<div style="display: inline-block;margin: 40px 20px 10px 20px;color: #1A237E">
  <i class="material-icons" style="display: inline-block; vertical-align: centered;">
    send
  </i>              
  <div style="display: inline-block;margin-left: 5px;padding-bottom:10px;font-size:24px;vertical-align: top;">
    Arquivos enviados {{ titulo }} {{ tribunal }}
  </div>
</div>  

<mat-dialog-content class="mat-typography">
  <div class="mat-elevation-z1">
  
    <table mat-table [dataSource]="dataSource">
  
      <ng-container matColumnDef="dataImportacao">
        <th mat-header-cell *matHeaderCellDef> Data importação </th>
        <td mat-cell *matCellDef="let element"> <b> {{element.dataImportacao}} </b> </td>
      </ng-container>
  
      <ng-container matColumnDef="nomeArquivoOriginal">
        <th mat-header-cell *matHeaderCellDef> Arquivo Original </th>
        <td mat-cell *matCellDef="let element"> 
          <a href="{{element.urlArquivoOriginal}}">
            {{element.nomeArquivoOriginal}} 
          </a>       
        </td>
      </ng-container>
  
      <ng-container matColumnDef="situacao">
        <th mat-header-cell *matHeaderCellDef> Situação </th>
        <td mat-cell *matCellDef="let element" [class]="getSitucaoClass(element.situacao)" style="cursor: help;"  > 
          <div style="height: 40px;display: inline-block;" [matTooltip]="getSitucaoMsg(element.situacao)" >
            <div style="display: inline-block;padding-top:5px">
              <span class="material-symbols-outlined" style="display: inline-block; vertical-align: bottom;margin-left: -5px;">
                {{ getSitucaoIcone(element.situacao) }}
              </span>              
              <div style="display: inline-block;margin-left: 5px;padding-bottom:1px">
                {{ element.situacao | situacao_arquivo  }} 
              </div>
            </div>
          </div>
        </td>     
      </ng-container>
      
      <ng-container matColumnDef="quantidadeRecebidos">
        <th mat-header-cell *matHeaderCellDef> Registros <br>Recebidos </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.quantidadeRecebidos }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantidadeSucesso">
        <th mat-header-cell *matHeaderCellDef> Registros <br> sem pendências </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.quantidadeSucesso }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantidadeRegistrosErro">
        <th mat-header-cell *matHeaderCellDef> Registros <br>com erros </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.quantidadeRegistrosErro }}
        </td>
      </ng-container> 
      
      <ng-container matColumnDef="quantidadeRegistrosAlerta">
        <th mat-header-cell *matHeaderCellDef> Registros <br>com alertas </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.quantidadeRegistrosAlerta }}
        </td>
      </ng-container> 

      <ng-container matColumnDef="erros">
        <th mat-header-cell *matHeaderCellDef> Erros e/ou Alertas </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class="bt-modal bt-modal-view" *ngIf="element.erros.length > 0 || element.alertas.length > 0" matTooltip="Visualizar erros e/ou alertas arquivo" matTooltipPosition="left"  (click)="showErrosArquivos(element)">
            <span class="material-symbols-outlined" style="display: inline-block; vertical-align: bottom;line-height: 0.8 !important;">
              visibility
            </span>            
            Visualizar
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>
  
    <mat-paginator 
        hidePageSize="true"
        [pageSizeOptions]="[5]"
        [length]="totalItems"
        showFirstLastButtons
        (page)="mudancaPagina($event)"
        aria-label="Selecione a página">
    </mat-paginator>

</div>
   
</mat-dialog-content>

<mat-dialog-actions>
  
  <div style="display: flex;justify-content: space-between;width: 100%;margin: 0px  20px;">
    <small >A situação 
       <span class="material-symbols-outlined" style="display: inline-block;color: #6A1B9A;font-size:17px; vertical-align: bottom;margin-left: 1px;">
        description
       </span>  <b style="color: #6A1B9A;">Erro de formato</b> indica que o arquivo enviado está fora do formato compatível.
      </small>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary" style="margin-top: -11px;">Fechar</button>
  </div>  
  
</mat-dialog-actions>