import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MsgAlert{
  title: string;
  content: string;
}

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['../../app.component.css', './alerta.component.css']
})
export class AlertaComponent {
  
  msg: MsgAlert = {
    title: "",
    content: ""
  };
 
  constructor(public dialogRef: MatDialogRef<AlertaComponent>, @Inject(MAT_DIALOG_DATA) msg : MsgAlert | string) {

    if(typeof msg === "object"){
      this.msg = msg;
    }else if(typeof msg === "string"){
      this.msg = {
        title: "",
        content: msg
      };
    }     
    
  }
 
}
