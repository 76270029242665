<style>
  td > span{
    width: 100%;text-align: center
  }
</style>

<div style="display: flex; justify-content: space-between;">
  <div>
    
    <div style="display: inline-block;padding-top:-50px;margin:33px 20px 0px 20px;color: #B71C1C">
      <i class="material-icons" style="display: inline-block; vertical-align: centered;">
        notifications
      </i>              
      <div style="display: inline-block;margin-left: 5px;padding-bottom:10px;font-size:24px;vertical-align: top;">
        {{ tribunal }} - Alertas quadro de pessoal e auxiliar ativos
      </div>
    </div>    
  </div>
  
  <a href="assets/Campos com Alertas-Quadro PessoaleAuxiliar.pdf" target="_blank">
    <button mat-stroked-button matTooltip="Clique para visualizar os campos com alertas do quadro pessoal e auxiliar" color="black" style="margin: 30px 25px;" >
      <mat-icon>info</mat-icon> Campos com alertas 
    </button>
  </a>  
  
</div>

<!--
<div style="margin: -5px 12px;">
  <mat-checkbox (change)="trocaExibirExcluidos()" color="primary" matTooltip="Exibir magistrados excluídos">
     <span class="sp-check-inativos"> Exibir excluídos </span> 
  </mat-checkbox>
</div>   -->

<mat-dialog-content class="mat-typography"> 
  
  <div class="mat-elevation-z1">
  
    <table mat-table [dataSource]="dataSource" style="width: 70vw;">
      
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef> CPF </th>
        <td mat-cell *matCellDef="let element"> {{element.cpf}} </td>
      </ng-container> 

      <ng-container matColumnDef="cargo">
        <th mat-header-cell *matHeaderCellDef> Cargo </th>
        <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
      </ng-container> 

      <ng-container matColumnDef="orgao">
        <th mat-header-cell *matHeaderCellDef> Órgão </th>
        <td mat-cell *matCellDef="let element"> {{element.orgao}} </td>
      </ng-container> 

      <ng-container matColumnDef="ativoCargo">
        <th mat-header-cell *matHeaderCellDef> Ativo </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.ativoCargo">Sim</span> 
          <span *ngIf="!element.ativoCargo">Não</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="excluido">
        <th mat-header-cell *matHeaderCellDef> Excluído </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.excluido">Sim</span> 
          <span *ngIf="!element.excluido">Não</span>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="totalErros">
        <th mat-header-cell *matHeaderCellDef> Total de alertas </th>
        <td mat-cell *matCellDef="let element"> {{element.totalErros}} </td>
      </ng-container>        

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>
  
    <mat-paginator 
      hidePageSize="true"
      [pageSizeOptions]="[5]"
      [length]="totalItems"
      showFirstLastButtons
      (page)="mudancaPagina($event)"
      aria-label="Selecione a página">
    </mat-paginator>

</div>
   
</mat-dialog-content>

<mat-dialog-actions align="end">  
  <div style="display: flex;justify-content: space-between;width: 99%; padding: 0px 10px 10px  10px;">

    <button mat-raised-button color="primary" matTooltip="Baixar todos alertas do quadro pessoal e auxiliar" (click)="downloadFile()">
      <mat-icon>download</mat-icon> Baixar alertas do quadro pessoal e auxiliar
    </button>  
    
    <button mat-raised-button [mat-dialog-close]="true" color="primary">Fechar</button>
    
  </div>
</mat-dialog-actions>