import { AppComponent } from "src/app/app.component";
import { environment } from '../../../environments/environment'; 
import jwt_decode from "jwt-decode";

export class Usuario{    

    codigoOrgao !: number;    
    cpf !: string;
  
    get logado(): boolean {
      return (localStorage.getItem('token') ? true : false) && this.tokenAtivo;
    }

    get token(): string{
        return localStorage.getItem('token') || "";
    }

    get tokenAtivo(){

        const token = localStorage.getItem('token');       
        const obj: any = jwt_decode(token == null ? "" : token);    
        
        const timestamp = obj.exp; 
        const dataExpiracaoToken = new Date(timestamp * 1000); // Multiplico por 1000 converter o timestamp em milissegundos
        const dataAtual = new Date();

        return dataAtual < dataExpiracaoToken;
    }

    get obterUsuarioLogado(): Usuario {
        return localStorage.getItem('usuario')
            ? JSON.parse(localStorage.getItem('usuario') || '{}')
            : null;
    }
      
    get orgao(): number | undefined {
        return localStorage.getItem('usuario')
            ? (JSON.parse(localStorage.getItem('usuario') || '{}') as Usuario).codigoOrgao
            : undefined;
    }

    get orgaoOrigem(): number | undefined {

        const codigos = [
            {tribunal: "4", orgao: "2"},
            {tribunal: "2", orgao: "1"},
            {tribunal: "3", orgao: "12728"},
            {tribunal: "64", orgao: "13309"},
            {tribunal: "1", orgao: "13891"},
            {tribunal: "71", orgao: "14"},
            {tribunal: "69", orgao: "12"},
            {tribunal: "79", orgao: "22"},
            {tribunal: "66", orgao: "9"},
            {tribunal: "73", orgao: "16"},
            {tribunal: "75", orgao: "20"},
            {tribunal: "76", orgao: "19"},
            {tribunal: "77", orgao: "18"},
            {tribunal: "81", orgao: "23"},
            {tribunal: "86", orgao: "28"},
            {tribunal: "87", orgao: "29"},
            {tribunal: "88", orgao: "31"},
            {tribunal: "90", orgao: "32"},
            {tribunal: "89", orgao: "33"},
            {tribunal: "91", orgao: "34"},
            {tribunal: "65", orgao: "8"},
            {tribunal: "67", orgao: "11"},
            {tribunal: "68", orgao: "10"},
            {tribunal: "70", orgao: "13"},
            {tribunal: "72", orgao: "15"},
            {tribunal: "74", orgao: "17"},
            {tribunal: "80", orgao: "25"},
            {tribunal: "78", orgao: "21"},
            {tribunal: "82", orgao: "24"},
            {tribunal: "83", orgao: "26"},
            {tribunal: "84", orgao: "27"},
            {tribunal: "85", orgao: "30"},
            {tribunal: "92", orgao: "13389"},
            {tribunal: "93", orgao: "13384"},
            {tribunal: "94", orgao: "12645"},
            {tribunal: "21", orgao: "13206"},
            {tribunal: "22", orgao: "13207"},
            {tribunal: "23", orgao: "13208"},
            {tribunal: "24", orgao: "13209"},
            {tribunal: "25", orgao: "13210"},
            {tribunal: "26", orgao: "13211"},
            {tribunal: "27", orgao: "13212"},
            {tribunal: "28", orgao: "13213"},
            {tribunal: "29", orgao: "13214"},
            {tribunal: "30", orgao: "13215"},
            {tribunal: "12", orgao: "13197"},
            {tribunal: "31", orgao: "13216"},
            {tribunal: "32", orgao: "13217"},
            {tribunal: "33", orgao: "13218"},
            {tribunal: "34", orgao: "13219"},
            {tribunal: "35", orgao: "13220"},
            {tribunal: "13", orgao: "13198"},
            {tribunal: "14", orgao: "13199"},
            {tribunal: "15", orgao: "13200"},
            {tribunal: "16", orgao: "13201"},
            {tribunal: "17", orgao: "13202"},
            {tribunal: "18", orgao: "13203"},
            {tribunal: "18", orgao: "86560"},
            {tribunal: "19", orgao: "13204"},
            {tribunal: "20", orgao: "13205"},
            {tribunal: "41", orgao: "13249"},
            {tribunal: "51", orgao: "13259"},
            {tribunal: "38", orgao: "13246"},
            {tribunal: "45", orgao: "13253"},
            {tribunal: "49", orgao: "13257"},
            {tribunal: "53", orgao: "13261"},
            {tribunal: "58", orgao: "13271"},
            {tribunal: "59", orgao: "13266"},
            {tribunal: "60", orgao: "13267"},
            {tribunal: "62", orgao: "13269"},
            {tribunal: "61", orgao: "13268"},
            {tribunal: "63", orgao: "13270"},
            {tribunal: "37", orgao: "13245"},
            {tribunal: "39", orgao: "13247"},
            {tribunal: "40", orgao: "13248"},
            {tribunal: "42", orgao: "13250"},
            {tribunal: "43", orgao: "13251"},
            {tribunal: "44", orgao: "13252"},
            {tribunal: "46", orgao: "13254"},
            {tribunal: "47", orgao: "13255"},
            {tribunal: "48", orgao: "13256"},
            {tribunal: "52", orgao: "13260"},
            {tribunal: "50", orgao: "13258"},
            {tribunal: "54", orgao: "13262"},
            {tribunal: "55", orgao: "13263"},
            {tribunal: "56", orgao: "13264"},
            {tribunal: "57", orgao: "13265"},
            {tribunal: "5", orgao: "3"},
            {tribunal: "6", orgao: "4"},
            {tribunal: "7", orgao: "5"},
            {tribunal: "8", orgao: "6"},
            {tribunal: "9", orgao: "7"},
            {tribunal: "95", orgao: "84633"},
            {tribunal: "10", orgao: "13221"},
            {tribunal: "36", orgao: "12991"}
          ];
      
          const arr = codigos.filter(codigo => Number(codigo.tribunal) == this.orgao);         
          return arr.length > 0 ? Number(arr[0].orgao) : 0;       
    }

    get obterTokenUsuario(): string {
        return localStorage.getItem('token')
            ? JSON.parse(localStorage.getItem('token') || '{}')
            : null;
    }   

    logar(cpf:string){        
        const baseUrl = environment.baseUrl;
        const redirect_uri = encodeURI(window.location.href);
        const url = `${baseUrl}auth?login_hint=${cpf}&response_mode=query&response_type=code&client_id=modulo-produtividade-mensal&redirect_uri=${redirect_uri}&scope=openid`;
        window.location.href = url;         
    }

    home(token: string){
        localStorage.setItem('token', token);
        localStorage.setItem('usuario', JSON.stringify(AppComponent.usuario) );        
    }

    deslogar(){
        localStorage.removeItem("token");
        localStorage.removeItem("usuario");
    }   
  }