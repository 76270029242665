import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import jwt_decode from "jwt-decode";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { InfoComponent } from './info.component';
import { CarregandoComponent } from 'src/app/components/carregando/carregando.component'; 
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../app.component.css', './home.component.css']
})
export class HomeComponent extends ListaInformacao implements OnInit, AfterViewInit {

  constructor(public dialog: MatDialog, private router: Router, private http : HttpClient){
    super();
  }
  
  ngAfterViewInit(): void {

    if(AppComponent.usuario.logado){
      this.dialog.open(CarregandoComponent, { disableClose: true });
    }    
    
  }

  ngOnInit(): void {
    if(!AppComponent.usuario.logado){
      this.router.navigate(["/login"]);
    } 

    HomeComponent.emissorCarregando.subscribe((valor:string)=>{
      this.listaCarregada(valor);
    });
  }  

  sair(){
    AppComponent.usuario.deslogar();
    this.router.navigate(["/login"]);    
  }

  titulo = environment.ambiente;
  toolbar = "toolbar " + environment.toolbarClass;
  orgaoOrigem =  AppComponent.usuario.orgaoOrigem;
  carregandoRelatorio = false;

  static emissorCarregando = new EventEmitter<string>();
  static emissorAtualizacaoDados = new EventEmitter<string>();

  //Carregando
  static listasCarregadas = {
    serventias:{
     lista: false,
     alertas: false,
     enviados: false
    },
    magistrados:{
      lista: false,
      alertas: false,
      enviados: false
    },
    servidores:{
      lista: false,
      alertas: false,
      enviados: false
    }
  }

  listaCarregada( valor: string ){
        
    switch(valor){
        case "serventias-lista":
            HomeComponent.listasCarregadas.serventias.lista = true;
            break;
        case "serventias-alertas":
          HomeComponent.listasCarregadas.serventias.alertas = true;
          break;    
        case "serventias-enviados":
          HomeComponent.listasCarregadas.serventias.enviados = true;
          break;  
        case "magistrados-lista":
            HomeComponent.listasCarregadas.magistrados.lista = true;
            break;
        case "magistrados-alertas":
          HomeComponent.listasCarregadas.magistrados.alertas = true;
          break;    
        case "magistrados-enviados":
          HomeComponent.listasCarregadas.magistrados.enviados = true;
          break; 
        case "servidores-lista":
            HomeComponent.listasCarregadas.servidores.lista = true;
            break;
        case "servidores-alertas":
          HomeComponent.listasCarregadas.servidores.alertas = true;
          break;    
        case "servidores-enviados":
          HomeComponent.listasCarregadas.servidores.enviados = true;
          break;  
    }                                     

    if( 
        valor.includes("serventias") && this.serventiasCarregadas ||
        valor.includes("magistrados") && this.magistradosCarregados ||
        valor.includes("servidores") && this.servidoresCarregados  
    ){

      // Fecho a janela de carregando
      this.dialog.closeAll(); 

      // Se precisar abro a janela de aviso
      if(AppComponent.usuario.logado && InfoComponent.exibirInfo){        
        //this.dialog.open(InfoComponent);
      }      
    }    
  }

  //Abas
  listasAbertas = {
    tribunal:{
      serventias: false,
      municipios: false, 
      competencias: false,
      tiposClassificacaoUnidade: false
    },
    magistrados:{
      informacoes: false,
      status: false, 
      tipos: false,
      cargos: false
    },
    servidoresColaboradores:{
      tribunal: false,
      areas: false, 
      tipos: false,
      cargos: false
    }
  }

  private selecionaPosionaPesquisa(lista: string, ajusteTpo: number){
    setTimeout(()=>{
      const idPequisa = "pesq-"+lista;
      const elemento = document.getElementById(idPequisa) as HTMLElement;
      elemento.click();        
      window.scrollTo( 0, elemento.clientTop + ajusteTpo );     
    },275);
  }

  abrirListaInformacao(lista: string){     

    switch(lista){
      case "serventias-tribunal":
        this.listasAbertas.tribunal.serventias = !this.listasAbertas.tribunal.serventias;
        if(this.listasAbertas.tribunal.serventias) this.selecionaPosionaPesquisa(lista, 125);
        break;
      case "serventias-municipios":
        this.listasAbertas.tribunal.municipios = !this.listasAbertas.tribunal.municipios;
        if(this.listasAbertas.tribunal.municipios) this.selecionaPosionaPesquisa(lista, 185);
        break; 
      case "serventias-competencias":
        this.listasAbertas.tribunal.competencias = !this.listasAbertas.tribunal.competencias;
        if(this.listasAbertas.tribunal.competencias) this.selecionaPosionaPesquisa(lista, 250);
        break;
      case "serventias-tipos-classificacao-unidade":
        this.listasAbertas.tribunal.tiposClassificacaoUnidade = !this.listasAbertas.tribunal.tiposClassificacaoUnidade;
        if(this.listasAbertas.tribunal.tiposClassificacaoUnidade) this.selecionaPosionaPesquisa(lista, 300);
        break;      
      case "magistrados-informacoes":
        this.listasAbertas.magistrados.informacoes = !this.listasAbertas.magistrados.informacoes;
        if(this.listasAbertas.magistrados.informacoes) this.selecionaPosionaPesquisa(lista, 125);
        break;    
      case "magistrados-status":
        this.listasAbertas.magistrados.status = !this.listasAbertas.magistrados.status;
        if(this.listasAbertas.magistrados.status) this.selecionaPosionaPesquisa(lista, 215);
        break;
      case "magistrados-tipos":
        this.listasAbertas.magistrados.tipos = !this.listasAbertas.magistrados.tipos;
        if(this.listasAbertas.magistrados.tipos) this.selecionaPosionaPesquisa(lista, 335);
        break;
      case "magistrados-cargos":
        this.listasAbertas.magistrados.cargos = !this.listasAbertas.magistrados.cargos;
        if(this.listasAbertas.magistrados.cargos) this.selecionaPosionaPesquisa(lista, 475);
        break;
      case "servidores-tribunal":
        this.listasAbertas.servidoresColaboradores.tribunal = !this.listasAbertas.servidoresColaboradores.tribunal;
        if(this.listasAbertas.servidoresColaboradores.tribunal) this.selecionaPosionaPesquisa(lista, 125);
        break;            
      case "servidores-areas":
        this.listasAbertas.servidoresColaboradores.areas = !this.listasAbertas.servidoresColaboradores.areas;
        if(this.listasAbertas.servidoresColaboradores.areas) this.selecionaPosionaPesquisa(lista, 215);
        break;
      case "servidores-tipos":
        this.listasAbertas.servidoresColaboradores.tipos = !this.listasAbertas.servidoresColaboradores.tipos;
        if(this.listasAbertas.servidoresColaboradores.tipos) this.selecionaPosionaPesquisa(lista, 335);
        break;
      case "servidores-cargos":
        this.listasAbertas.servidoresColaboradores.cargos = !this.listasAbertas.servidoresColaboradores.cargos;
        if(this.listasAbertas.servidoresColaboradores.cargos) this.selecionaPosionaPesquisa(lista, 475);
        break;    
    } 
  }

  selecionarTabelaAuxiliar(tabela:string){    

    const abaTabela = document.getElementById("abaTabelasAuxiliares") as HTMLElement;
    abaTabela.click();

    setTimeout(()=>{

      let abrir = false;

      switch(tabela){
        case "serventias-municipios":         
          if(!this.listasAbertas.tribunal.municipios) abrir = true;
          break;
        case "serventias-competencias":
          if(!this.listasAbertas.tribunal.competencias) abrir = true;
          break;
        case "serventias-tipos-classificacao-unidade":
          if(!this.listasAbertas.tribunal.tiposClassificacaoUnidade) abrir = true;
          break;    
      }
      
      if(abrir) (document.getElementById("sp-tabela-"+tabela) as HTMLElement).click(); 
      this.selecionaPosionaPesquisa(tabela, 200);

    },750);
  }

  baixar(arquivo:string){
    const link = window.document.createElement("a");
    link.setAttribute("href", `assets/${arquivo}`);
    link.setAttribute("download", `${arquivo}`);
    link.click();   
  }

  async baixarTabelasAuxiliares(){
    
    this.dialog.open(CarregandoComponent, { disableClose: true });

    //array geral
    const arrAbas = [];
    
    const urlAreaAtuacaoQuadro = `${environment.API}/v2/areas`; 
    const urlEntrancias = `${environment.API}/v2/entrancias`;
    const urlCargosMagistrados = `${environment.API}/v2/tribunais/magistrados/cargos`;
    const urlSituacoesMagistrados = `${environment.API}/v2/tribunais/magistrados/situacoes`;
    const urlCargosServidores = `${environment.API}/v2/tribunais/servidores/cargos`;
    const urlSituacoesServidores = `${environment.API}/v2/tribunais/servidores/situacoes`;
    const urlCotas = `${environment.API}/v2/cotas`;
    const urlDeficiencias = `${environment.API}/v2/deficiencias`;
    const urlTiposUnidadeJudiciaria = `${environment.API}/v2/tipos`; 
    const urlSexos = `${environment.API}/v2/sexos`; 
    const urlIdentidadesGenero = `${environment.API}/v2/identidadesGenero`;     
    const urlRacaCor = `${environment.API}/v2/etnias`; 
    const urlPromocoes = `${environment.API}/v2/promocoes`; 
    const urlCompetencias = `${environment.API}/v2/competencias`; 
    const urlClassificacoes = `${environment.API}/v2/classificacoes`; 
           
    const resultadoAreaAtuacaoQuadro:any = await lastValueFrom(this.http.get(urlAreaAtuacaoQuadro));
    const resultadoEntrancia:any = await lastValueFrom(this.http.get(urlEntrancias));
    const resultadoCargosMagistrados:any = await lastValueFrom(this.http.get(urlCargosMagistrados));
    const resultadoSituacoesMagistrados:any = await lastValueFrom(this.http.get(urlSituacoesMagistrados));
    const resultadoCargosServidores:any = await lastValueFrom(this.http.get(urlCargosServidores));
    const resultadoSituacoesServidores:any = await lastValueFrom(this.http.get(urlSituacoesServidores));
    const resultadoCotas:any = await lastValueFrom(this.http.get(urlCotas));
    const resultadoDeficiencias:any = await lastValueFrom(this.http.get(urlDeficiencias));
    const resultadoTiposUnidadeJudiciaria:any = await lastValueFrom(this.http.get(urlTiposUnidadeJudiciaria));
    const resultadoSexos:any = await lastValueFrom(this.http.get(urlSexos));
    const resultadoIdentidadesGenero:any = await lastValueFrom(this.http.get(urlIdentidadesGenero));
    const resultadoRacaCor:any = await lastValueFrom(this.http.get(urlRacaCor));
    const resultadoPromocoes:any = await lastValueFrom(this.http.get(urlPromocoes));
    const resultadoCompetencias:any = await lastValueFrom(this.http.get(urlCompetencias));
    const resultadoClassificacoes:any = await lastValueFrom(this.http.get(urlClassificacoes));
    
    arrAbas.push({ titulo: `Área atuação quadro`, conteudo: resultadoAreaAtuacaoQuadro.result.map((obj:any) => ({ seq_area_atuacao: obj.id, dsc_area_atuacao: obj.descricao })).sort((a:any, b:any) => a.seq_area_atuacao - b.seq_area_atuacao) });
    arrAbas.push({ titulo: `Entrância`, conteudo: resultadoEntrancia.result.map((obj:any) => ({ seq_entrancia: obj.id, dsc_entrancia: obj.descricao })).sort((a:any, b:any) => a.seq_entrancia - b.seq_entrancia) });
    arrAbas.push({ titulo: `Cargos magistrados`, conteudo: resultadoCargosMagistrados.result.map((obj:any) => ({ seq_cargo: obj.id, dsc_cargo: obj.descricao })).sort((a:any, b:any) => a.seq_cargo - b.seq_cargo) });
    arrAbas.push({ titulo: `Sit. prof. atual magistrado`, conteudo: resultadoSituacoesMagistrados.result.map((obj:any) => ({ seq_situacao: obj.id, dsc_situacao: obj.descricao })).sort((a:any, b:any) => a.seq_situacao - b.seq_situacao) });
    arrAbas.push({ titulo: `Cargos servidores`, conteudo: resultadoCargosServidores.result.map((obj:any) => ({ seq_cargo: obj.id, dsc_cargo: obj.descricao })).sort((a:any, b:any) => a.seq_cargo - b.seq_cargo) });
    arrAbas.push({ titulo: `Sit. prof. atual servidor`, conteudo: resultadoSituacoesServidores.result.map((obj:any) => ({ seq_situacao: obj.id, dsc_situacao: obj.descricao })).sort((a:any, b:any) => a.seq_situacao - b.seq_situacao) });
    arrAbas.push({ titulo: `Regime de cotas`, conteudo: resultadoCotas.result.map((obj:any) => ({ seq_cota: obj.id, dsc_cota: obj.descricao })).sort((a:any, b:any) => a.seq_cota - b.seq_cota) });
    arrAbas.push({ titulo: `Deficiência`, conteudo: resultadoDeficiencias.result.map((obj:any) => ({ seq_deficiencia: obj.id, dsc_deficiencia: obj.descricao })).sort((a:any, b:any) => a.seq_deficiencia - b.seq_deficiencia) });
    arrAbas.push({ titulo: `Tipo unidade judiciária`, conteudo: resultadoTiposUnidadeJudiciaria.result.map((obj:any) => ({ seq_tipo_unidade: obj.id, dsc_tipo_unidade: obj.descricao, sigla: obj.sigla })).sort((a:any, b:any) => a.seq_tipo_unidade - b.seq_tipo_unidade) });
    arrAbas.push({ titulo: `Sexo`, conteudo: resultadoSexos.result.map((obj:any) => ({ seq_sexo: obj.id, dsc_sexo: obj.descricao })).sort((a:any, b:any) => a.seq_sexo - b.seq_sexo) });
    arrAbas.push({ titulo: `Identidade gênero`, conteudo: resultadoIdentidadesGenero.result.map((obj:any) => ({ seq_identidade_genero: obj.id, dsc_identidade_genero: obj.descricao })).sort((a:any, b:any) => a.seq_identidade_genero - b.seq_identidade_genero) }); 
    arrAbas.push({ titulo: `Raça_cor`, conteudo: resultadoRacaCor.result.map((obj:any) => ({ seq_raca_cor: obj.id, dsc_raca_cor: obj.descricao })).sort((a:any, b:any) => a.seq_raca_cor - b.seq_raca_cor) });
    arrAbas.push({ titulo: `Forma de promoção magistrado`, conteudo: resultadoPromocoes.result.map((obj:any) => ({ seq_promocao_magistrado: obj.id, dsc_promocao_magistrado: obj.descricao })).sort((a:any, b:any) => a.seq_promocao_magistrado - b.seq_promocao_magistrado) }); 
    arrAbas.push({ titulo: `Competencias juízo`, conteudo: resultadoCompetencias.result.map((obj:any) => ({ seq_competencia: obj.id, dsc_competencia: obj.descricao })).sort((a:any, b:any) => a.seq_competencia - b.seq_competencia) }); 
    arrAbas.push({ titulo: `Classificação unid. judiciária`, conteudo: resultadoClassificacoes.result.map((obj:any) => ({ tipo_unid: obj.tipo.sigla, seq_classificacao_unid: obj.id, dsc_classificacao_unid: obj.descricao })).sort((a:any, b:any) => a.seq_classificacao_unid - b.seq_classificacao_unid) }); 
    
    this.downloadExcelAbas("Tabelas auxiliares MPM.xlsx" , arrAbas);
    this.dialog.closeAll();
  }

  direcionarBaixarTabelas(){
    const abaTabela = document.getElementById("abaTabelasAuxiliares") as HTMLElement; 
    abaTabela.click();
  }

  baixarCSV(nome:string){    
    
    if(this.carregandoRelatorio) return;
        
    const url = `${environment.API}/v2/arquivos/${nome}/gerarCSV`;
    
    this.http.get(url, {responseType: 'blob'}).subscribe((resultado: any) => { 
      const link = window.document.createElement("a");
      link.setAttribute("href", window.URL.createObjectURL(resultado));
      link.setAttribute("download", nome + "_alertas_gerais" + ".csv");
      link.click();
      this.carregandoRelatorio = false;
    }); 

    this.carregandoRelatorio = true;    
  }

  get tribunal(){     
    const token = localStorage.getItem('token');
    if (token == null) return "";    
    const obj: any = jwt_decode(token);          
    return  obj.corporativo[0].dsc_tribunal_pai;
  }

  get serventiasCarregadas(){
    return HomeComponent.listasCarregadas.serventias.lista &&
           HomeComponent.listasCarregadas.serventias.alertas &&
           HomeComponent.listasCarregadas.serventias.enviados; 
  }


  get magistradosCarregados (){ 
    return HomeComponent.listasCarregadas.magistrados.lista &&
           HomeComponent.listasCarregadas.magistrados.alertas &&
           HomeComponent.listasCarregadas.magistrados.enviados;      
  }

  get servidoresCarregados(){ 
    return HomeComponent.listasCarregadas.servidores.lista &&
           HomeComponent.listasCarregadas.servidores.alertas &&  
           HomeComponent.listasCarregadas.servidores.enviados;
  }

  loadAba(evento:any){

    switch(evento.index){
      
      // serventias
      case 0: 
        break;
      
      // magistrados  
      case 1:

        if(!this.magistradosCarregados){
          this.dialog.open(CarregandoComponent, { disableClose: true });
          HomeComponent.emissorAtualizacaoDados.emit("magistrado");
        }
        
        break;
      
      // quadro pessoal  
      case 2:

        if(!this.servidoresCarregados){
          this.dialog.open(CarregandoComponent, { disableClose: true });  
          HomeComponent.emissorAtualizacaoDados.emit("servidor");
        }  
        break;
      
      // tabelas auxiliares
      case 3:
        break;
            
    }
  }
 
}