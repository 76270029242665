<div class="mat-elevation-z2">

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
        <mat-label>Buscar pelo <i>id</i> ou <i>descrição</i> de cargos do quadro de pessoal e auxiliar</mat-label>
        <input type="text" matInput id="pesq-servidores-cargos" (keyup)="filtrarCargosServidores($event)">     
      </mat-form-field>
    </form>
  
    <table mat-table [dataSource]="dataSource" style="width: 650px;">
  
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>  
  
      <ng-container matColumnDef="descricao">
        <th mat-header-cell *matHeaderCellDef> Descrição </th>
        <td mat-cell *matCellDef="let element"> {{element.descricao}} </td>
      </ng-container>
  
      <ng-container matColumnDef="descricao_completa">
        <th mat-header-cell *matHeaderCellDef> Descrição Completa </th>
        <td mat-cell *matCellDef="let element"> {{element.descricaoCompleta}} </td>
      </ng-container>  
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>
  
    <mat-paginator [pageSizeOptions]="[5]"
                    showFirstLastButtons
                    aria-label="Selecione a página">
    </mat-paginator>
  
    <div style="padding: 10px 10px 20px 10px;display: flex; justify-content: flex-end">
      <button mat-raised-button color="primary" (click)="downloadFile()">
        <mat-icon>download</mat-icon> Baixar lista cargos servidores e colaboradores
      </button>  
    </div>
      
  </div>  