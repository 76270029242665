<div style="display: flex;justify-content: space-around;margin-top: 30px ;">
    <img src="assets/logo/MPM_Logo_Principal_Horizontal.png" style="width: 180px;height: auto">
</div>
<h3 style="display: inline-block;margin-left: 30px">{{ msg.title }}</h3>

<mat-dialog-content class="mat-typography" id="conteudo">
    <div>
        {{ msg.content }}
    </div>
    <div style="text-align: center;margin-top:30px">
        <button mat-raised-button [mat-dialog-close] cdkFocusInitial color="primary">Fechar</button>
    </div>    
</mat-dialog-content>     