import { AfterContentInit, Component, Input } from '@angular/core';
import { ArquivoUtil } from 'src/app/util/ArquivoInformacao';

const versoes: string[] = [
  "19/02/2024",
  "10/05/2024"
]

@Component({
  selector: 'app-arquivo-exemplo-download',
  templateUrl: './arquivo-exemplo-download.component.html',
  styleUrls: ['./arquivo-exemplo-download.component.css', '../../app.component.css']
})
export class ArquivoExemploDownloadComponent extends ArquivoUtil implements AfterContentInit {
  
  @Input()
  tipo!: string;
  tooltip: string = '';
  ultimaVersao = versoes[versoes.length-1];

  ngAfterContentInit(): void {
    this.tooltip = 'Baixar arquivo modelo de envio ' + this.getTipoDescricao(this.tipo);
  }

  download(){    

    let arquivo:string = "";

    switch(this.tipo){
      case "serventia":
        arquivo = "arquivo_modelo_serventias";
        break;
      case "magistrado":
        arquivo = "arquivo_modelo_magistrados(as)";
        break; 
      case "servidor":
        arquivo = "arquivo_modelo_quadro_pessoal_e_auxiliar";
        break;    
    }
    
    const link = window.document.createElement("a");
    link.setAttribute("href", "assets/"+arquivo+".xlsx");
    link.setAttribute("download", arquivo+".xlsx");
    link.click();
    // document.body.removeChild(link);    
  }
}