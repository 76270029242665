<div class="mat-elevation-z2" style="margin-top:24px">

  <form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
      <mat-label>Buscar pelo <i>Código Serventia</i> ou <i>nome</i> da serventia</mat-label>
      <input type="text" matInput id="pesq-serventias-tribunal" (keyup)="filtrarServentias()">     
    </mat-form-field>
  </form>

  <table mat-table [dataSource]="dataSource" style="width: 650px;margin-top:-23px">

    <ng-container matColumnDef="codigoServentia">
      <th mat-header-cell *matHeaderCellDef> Código <br> Serventia </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)">{{element.codigoServentia}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="nomeServentia">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)">{{element.nomeServentia}} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="codigoNomeMunicipio">
      <th mat-header-cell *matHeaderCellDef> Cód - Nome Município </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)"> {{element.codigoNomeMunicipio}} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="UF">
      <th mat-header-cell *matHeaderCellDef> UF </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)"> {{element.UF}} </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
  </table>

  <mat-paginator
                hidePageSize="true" 
                [pageSizeOptions]="[5]"
                [length]="totalItems"
                showFirstLastButtons
                aria-label="Selecione a página"
                (page)="mudancaPagina($event)"
                >
  </mat-paginator>

  <div style="padding: 10px 10px 20px 10px;display: flex; justify-content: space-between">
    <mat-checkbox (change)="trocaExibirInativos()" color="primary" matTooltip="Exibir serventias inativas">
      <span class="sp-check-inativos"> Exibir Inativos </span> 
    </mat-checkbox>
    <button mat-raised-button color="primary" (click)="downloadFile()" matTooltip="Baixar todas as serventias" matTooltipPosition="left">
      <mat-icon>download</mat-icon> Baixar lista de serventias tribunal
    </button>  
  </div>
    
</div>  