import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment'; 

export interface CargoMagistrado {
  id: string;
  descricao: string 
}

@Component({
  selector: 'app-lista-magistrados-cargos',
  templateUrl: './lista-magistrados-cargos.component.html',
  styleUrls: ['./lista-magistrados-cargos.component.css']
})
export class ListaMagistradosCargosComponent extends ListaInformacao implements AfterViewInit{

  cargosMagistrados: CargoMagistrado[] = [];
  displayedColumns: string[] = ['id','descricao'];
  dataSource = new MatTableDataSource<CargoMagistrado>(this.cargosMagistrados);

  constructor(private http : HttpClient) {
    
    super();
    const url = `${environment.API}/v2/tribunais/magistrados/cargos`;
    
    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.cargosMagistrados.push(  {
          id: element.id,
          descricao: element.descricao
        } )
      });  

      this.dataSource.data  = this.cargosMagistrados;
          
    });
  }  

  filtrarCargosMagistrados(event: any): CargoMagistrado[] {   
    
    const filterValue = event.target.value.toLowerCase();

    const cargosMagistradosFiltrados = this.cargosMagistrados.filter(cargos => {
      const id = cargos.id.toString();
      const descricao = cargos.descricao.toLowerCase();
      return id.includes(filterValue) || descricao.includes(filterValue);
    });
    
    this.dataSource.data  = filterValue == "" ? this.cargosMagistrados : cargosMagistradosFiltrados;
    return cargosMagistradosFiltrados;
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  override downloadFile(){
    super.downloadFile("magistrados_cargos", this.cargosMagistrados);
  } 
}
