import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';

export interface AreaServidor {
  seqArea: string;
  descricao: string
}

@Component({
  selector: 'app-lista-servidores-areas',
  templateUrl: './lista-servidores-areas.component.html',
  styleUrls: ['./lista-servidores-areas.component.css']
})
export class ListaServidoresAreasComponent extends ListaInformacao implements AfterViewInit{

  areasServidores: AreaServidor[] = [];
  displayedColumns: string[] = ['seq_area','descricao'];
  dataSource = new MatTableDataSource<AreaServidor>(this.areasServidores);

  constructor(private http : HttpClient) {
    
    super();
    const url = `${environment.API}/v2/areas`;
    
    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.areasServidores.push(  {
          seqArea: element.id,
          descricao: element.descricao
        } )
      });  

      this.dataSource.data  = this.areasServidores;          
    });
  }  

  filtrarAreasServidores(event: any): AreaServidor[] {   
    
    const filterValue = event.target.value.toLowerCase();
    
    const areasServidoresFiltrados = this.areasServidores.filter(areas => {
      const id = areas.seqArea.toString();
      const descricao = areas.descricao.toLowerCase();
      return id.includes(filterValue) || descricao.includes(filterValue);
    });

    this.dataSource.data  = filterValue == "" ? this.areasServidores : areasServidoresFiltrados;
    return areasServidoresFiltrados;
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  override downloadFile(){
    super.downloadFile("servidor_tipos", this.areasServidores);
  } 
}