export class ArquivoUtil{

    getTipoURL(tipo: string): string{
        switch(tipo){
            case "serventia": 
                return "serventias";
            case "magistrado": 
                return "magistrados";
            case "servidor": 
                return "servidores";    
        }

        return "";
    }

    getTipoDescricao(tipo: string): string{
        switch(tipo){
            case "serventia": 
                return "serventias";
            case "magistrado": 
                return "magistrados(as)";
            case "servidor": 
                return "quadro de pessoal e auxiliar";    
        }

        return "";
    }

    public formataData(str: string): string{
        const arr1 = str.split("T");
        const arr2 = arr1[0].split("-");
        return `${arr2[2]}/${arr2[1]}/${arr2[0]}`;  
    }

    public formataDataHora(str: string): string{ 
        const arr = str.split("T");
        const arrData = arr[0].split("-");       
        return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${arr[1].substring(0,8)}`;  
    }
}