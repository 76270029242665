import { Component, ViewChild } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ListaInformacao } from "src/app/util/ListaInformacao";
import { NotificacaoMagistrado } from "./notificacao.model";
import { ArquivosNotificacaoComponent, NotificacaoExibirExcluidos } from "./arquivos-notificacao.component";
import { environment } from "src/environments/environment";
import { AppComponent } from "src/app/app.component";
import { CarregandoComponent } from "../carregando/carregando.component";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'lista-notificacoes',
    templateUrl: 'lista-notificacoes-magistrados.component.html',
    styleUrls: ['lista-notificacoes.component.css','../../app.component.css']
})
export class ListaNotificacoesMagistrados extends ListaInformacao{  
        
    tribunal: string = "";
    pagina: number = 0;
    displayedColumns: string[] = [ "nome", "cpf", "orgao", "ativoCargo", "totalErros" ];   
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;       
    exibirExcluidos = false;
    totalItems: number = 0;

    constructor(
        public dialogRef: MatDialogRef<ListaNotificacoesMagistrados>, 
        private http: HttpClient, 
        public dialog: MatDialog
    ) {
        super();
        this.setNotificacoes();
    }
    
    setNotificacoes(page: number = 0){
        
        const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/magistrados/alertas/?page=${page}&size=5`;

        this.http.get(url).subscribe((resultado: any) => {

            const notificacoes: NotificacaoMagistrado[] = resultado.result.content;

            if(notificacoes.length > 0) this.tribunal = notificacoes[0].siglaTribunal;
                    
            this.dataSource.data = notificacoes.map((notificacao)=>{

                const totalErros =  ( notificacao.naturalidadeErro != "" ? 1 : 0 ) +
                                    ( notificacao.nascimentoErro != "" ? 1 : 0 ) +
                                    ( notificacao.emailErro != "" ? 1 : 0 ) +
                                    ( notificacao.sexoErro != "" ? 1 : 0 ) +
                                    ( notificacao.identidadeGeneroErro != "" ? 1 : 0 ) +
                                    ( notificacao.racaCorErro != "" ? 1 : 0 ) +
                                    ( notificacao.deficienciaErro != "" ? 1 : 0 ) +
                                    ( notificacao.cotaErro != "" ? 1 : 0 ) +
                                    ( notificacao.cargoErro != "" ? 1 : 0 ) +                                
                                    ( notificacao.posseErro != "" ? 1 : 0 ) +                                
                                    ( notificacao.cargoAtualErro != "" ? 1 : 0 ) +
                                    ( notificacao.dataInicioCargoAtualErro != "" ? 1 : 0 ) +
                                    ( notificacao.dataFimCargoAtualErro != "" ? 1 : 0 ) + 
                                    ( notificacao.promocaoErro != "" ? 1 : 0 ) +
                                    ( notificacao.dataPromocaoErro != "" ? 1 : 0 );

                return { 
                    nome: notificacao.nome,
                    cpf: notificacao.cpf,
                    orgao: notificacao.orgao,                               
                    ativoCargo: notificacao.ativoCargo,
                    excluido: notificacao.excluido,
                    totalErros: totalErros
                }                    
            });

            this.totalItems = resultado.result.totalElements;
            this.paginator.pageIndex = page;
        });        
    }    
  
    mudancaPagina(event: PageEvent) {
        this.setNotificacoes(event.pageIndex);
    }       
     
    override downloadFile(){

        this.dialog.open(CarregandoComponent, { disableClose: true });

        const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/magistrados/alertas/`;

        this.http.get(url).subscribe((resultado: any) => {

            const notificacoesCSV =  resultado.result.map((notificacao: NotificacaoMagistrado)=>{
                return { 
                    "CPF": notificacao.cpf,
                    "Nome": notificacao.nome,
                    "Órgão de lotação do magistrado(a)": notificacao.orgao,
                    "Alerta: Naturalidade": notificacao.naturalidadeErro,
                    "Alerta: Data Nascimento": notificacao.nascimentoErro,
                    "Alerta: Email": notificacao.emailErro,
                    "Alerta: Sexo": notificacao.sexoErro,
                    "Alerta: Identidade de gênero": notificacao.identidadeGeneroErro,
                    "Alerta: Raça / Cor": notificacao.racaCorErro,
                    "Alerta: Deficiência": notificacao.deficienciaErro,
                    "Alerta: Foi aprovado(a) em Regime de Cotas": notificacao.cotaErro,
                    "Alerta: Cargo": notificacao.cargoErro,
                    "Alerta: Data posse": notificacao.posseErro,
                    "Alerta: Forma de promoção Desembargador(a)/Juiz(a) Titular": notificacao.promocaoErro,
                    "Alerta: Data da promoção": notificacao.dataPromocaoErro,
                    "Alerta: Situação profissional atual": notificacao.cargoAtualErro,
                    "Alerta: Data de início da situação": notificacao.dataInicioCargoAtualErro,
                    "Alerta: Data de saída da situação": notificacao.dataFimCargoAtualErro
                };
            });

            super.downloadFile("alertas_magistrados", notificacoesCSV );
            this.dialog.closeAll();
        });    
    }

    trocaExibirExcluidos(){

        this.exibirExcluidos = !this.exibirExcluidos; 
        this.exibirExcluidos ? this.displayedColumns.splice(4, 0, "excluido") : this.displayedColumns.splice(4,1);
        this.setNotificacoes();

        const notficacaoExibir: NotificacaoExibirExcluidos = {
            tipo: "magistrado",
            exibirExcluidos: this.exibirExcluidos
        }

        ArquivosNotificacaoComponent.emissorExibirExcluidos.emit(notficacaoExibir);            
    }    
}