<mat-card style="display: block;margin-bottom:22px">
    <button mat-button [matTooltip]="tooltip" matTooltipPosition="left" id="bt" (click)="download()">
        <div id="box-icon">         
            <mat-icon>article</mat-icon>
        </div> 
        <div style="margin-top: 5px;"> 
            Arquivo modelo
        </div>         
    </button>

    <small style="float: right;color: green;">
        Data última atualização modelo: <b> {{ ultimaVersao }} </b>
    </small> 
</mat-card>
