import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from './app.component';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // Obtém o token do armazenamento local ou de onde ele estiver armazenado
    const token = localStorage.getItem('token');

    const abertos = [ 
      "/api/v2/login/",
      "/api/v2/municipios/",
      "/api/v2/cotas/",
      "/api/v2/sexos/",
      "/api/v2/identidadesGenero/",
      "/api/v2/entrancias/",
      "/api/v2/etnias/",
      "/api/v2/deficiencias/",
      "/api/v2/areas/",
      "/api/v2/tribunais/serventias/",
      "/api/v2/competencias/",
      "/api/v2/classificacoes/",
      "/api/v2/tribunais/magistrados/",
      "/api/v2/tribunais/servidores/",
      "/api/v2/tribunais/orgao/"
    ];

    const urlAberta = abertos.some( url => request.url.includes(url) )
   
    // Verifica se há um token e adiciona-o aos cabeçalhos da solicitação
    if (token && !urlAberta) {
      request = request.clone({
        setHeaders: {       
         'Authorization': `Bearer ${AppComponent.usuario.token}`
        }
      });
    }

    return next.handle(request);
  }
}
