<style>
  
    :host {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  
    p {
      margin: 0;
    }
  
    .spacer {
      flex: 1;
    } 
       
    @media screen and (max-width: 767px) {
     
    }
  
    @media screen and (max-width: 575px) {
      
    }
  
  </style>
  
  <div [class]="toolbar" role="banner" style="height: 70px;">   
    <img src="assets/logo/MPM_Logo_Principal_Horizontal.png" style="height: 40px;width: auto;margin-left: 24px;" > 
    <h2 style="margin-left: 20px;"> {{ titulo }}</h2>
    <div class="spacer"></div>
    <img scr="assets/cnj.png"   />
    <img src="assets/banner-datajud.png" id="logo-datajud">  
  </div>
  
  <div id="card-principal" role="main">
  
    <mat-card style="min-height: 95% !important;">
        <mat-card style="margin: 7% auto auto auto; width: 50%;box-shadow: -2px 10px 10px 3px #aaaaaa;" >
            <div style="width: 100%;background-color: #294CC9;border-top-left-radius: 5px;border-top-right-radius: 5px;text-align: center;padding: 20px 0px;">
                <!-- <h1 style="display: inline-block;color:#fff;margin-left:20px">MPM</h1>
                <h2 style="display: inline-block;margin-left:20px;color: #fff">                  
                  
                </h2> -->
                <img src="assets/logo/MPM_Logo_Branca_Horizontal.png" style="height: 50px;width: auto;margin-left: 24px;" >               
            </div>
            <div style="width: 95%;padding: 0px 0px 0px 20px">
                <i class="material-icons" style="display: inline-block; vertical-align: centered;">
                    account
                </i> 
                <mat-form-field class="example-full-width" style="width: 100%;">
                    <mat-label>Nº CPF</mat-label>
                    <input matInput placeholder="Informe seu CPF" id="cpf" (keyup.enter)="entrar()">
                </mat-form-field>
            </div>   
            <div style="width: 95%;padding: 0px 0px 20px 20px">
                <button mat-raised-button style="float: right;background-color:#294CC9;color:#fff" (click)="entrar()">Entrar</button>
            </div>        
        </mat-card>             
    </mat-card>
  
    <div style="height:60px"></div>
  
    <footer>
      <img src="assets/cnj.jpg" style="width: 100px;margin: 10px 0px 0px 20px">
      <img src="assets/pdjp-marcas-simbolo.svg" style="width: 40px;height: auto;margin-right: 20px;float:right">
    </footer>
  
</div>  