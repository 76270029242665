import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ListaInformacao } from "src/app/util/ListaInformacao";
import { NotificacaoServentia } from "./notificacao.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppComponent } from "src/app/app.component";
import { CarregandoComponent } from "../carregando/carregando.component";

@Component({
    selector: 'lista-notificacoes-serventias',
    templateUrl: 'lista-notificacoes-serventias.component.html',
    styleUrls: ['lista-notificacoes.component.css', '../../app.component.css']
})
export class ListaNotificacoesServentias extends ListaInformacao {

    tribunal: string = "";
    pagina: number = 0;
    displayedColumns: string[] = ["idOrigem", "nome", "ativo", "totalErros"];
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    totalItems: number = 0;

    constructor(
        public dialogRef: MatDialogRef<ListaNotificacoesServentias>, 
        private http: HttpClient, public dialog: MatDialog
    ) {
        super();
        this.setNotificacoes();
    }

    setNotificacoes(page: number = 0) {

        const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/serventias/alertas/?page=${page}&size=5`;

        this.http.get(url).subscribe((resultado: any) => {

            const notificacoes: NotificacaoServentia[] = resultado.result.content;

            if (notificacoes.length > 0) this.tribunal = notificacoes[0].siglaTribunal;

            this.dataSource.data = notificacoes.map((notificacao) => {

                const totalErros = (notificacao.enderecoErro != "" ? 1 : 0) +
                    (notificacao.cepErro != "" ? 1 : 0) +
                    (notificacao.telefoneErro != "" ? 1 : 0) +
                    (notificacao.emailErro != "" ? 1 : 0) +
                    (notificacao.codigoUnidadeErro != "" ? 1 : 0) +
                    (notificacao.juizoDigitalErro != "" ? 1 : 0) +
                    (notificacao.dataAdesaoErro != "" ? 1 : 0) +
                    (notificacao.dataTerminoErro != "" ? 1 : 0) +
                    (notificacao.tipoUnidadeErro != "" ? 1 : 0) +
                    (notificacao.classificacaoErro != "" ? 1 : 0) +
                    (notificacao.instalacaoErro != "" ? 1 : 0) +
                    (notificacao.abrangenciaErro != "" ? 1 : 0) +
                    (notificacao.competenciaErro != "" ? 1 : 0) +
                    (notificacao.geolocalizacaoErro != "" ? 1 : 0) +
                    (notificacao.entranciaErro != "" ? 1 : 0) +
                    (notificacao.balcaoVirtualErro != "" ? 1 : 0) +
                    (notificacao.linkBalcaoErro != "" ? 1 : 0) +
                    (notificacao.telefoneBalcaoErro != "" ? 1 : 0) +
                    (notificacao.nucleoErro != "" ? 1 : 0) +
                    (notificacao.dataInicioNucleoErro != "" ? 1 : 0) +
                    (notificacao.dataFimNucleoErro != "" ? 1 : 0);
                return {
                    idOrigem: notificacao.idOrigem,
                    nome: notificacao.nome,
                    ativo: notificacao.ativo,
                    totalErros: totalErros
                }
            });

            this.totalItems = resultado.result.totalElements;
            this.paginator.pageIndex = page;
        });
    }

    mudancaPagina(event: PageEvent) {
        this.setNotificacoes(event.pageIndex);
    }
 
    override downloadFile() {

        this.dialog.open(CarregandoComponent, { disableClose: true });

        const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/serventias/alertas/`;

        this.http.get(url).subscribe((resultado: any) => {

            const notificacoesCSV = resultado.result.map((notificacao:NotificacaoServentia) => {
                return {
                    "Código Serventia": notificacao.id,
                    "Nome da Serventia": notificacao.nome,
                    "Status": notificacao.ativo == true ? "Ativo" : "Inativo",
                    "Alerta: Endereço": notificacao.enderecoErro,
                    "Alerta: CEP": notificacao.cepErro,
                    "Alerta: Telefone": notificacao.telefoneErro,
                    "Alerta: Email": notificacao.emailErro,
                    "Alerta: Código da Unidade de Origem (OOOO)": notificacao.codigoUnidadeErro,
                    "Alerta: Juizo 100% Digital": notificacao.juizoDigitalErro,
                    "Alerta: Data Adesão": notificacao.dataAdesaoErro,
                    "Alerta: Data Término da Adesão": notificacao.dataTerminoErro,
                    "Alerta: Tipo de Unidade Judiciária": notificacao.tipoUnidadeErro,
                    "Alerta: Classificação da Unidade Judiciária": notificacao.classificacaoErro,
                    "Alerta: Instalação": notificacao.instalacaoErro,
                    "Alerta: Municípios Abrangidos": notificacao.abrangenciaErro,
                    "Alerta: Competências": notificacao.competenciaErro,
                    "Alerta: Geolocalização (latitude e longitude não correspondem aos limites geográficos do município informado)": notificacao.geolocalizacaoErro,
                    "Alerta: Entrância": notificacao.entranciaErro,
                    "Alerta: Balcão Virtual": notificacao.balcaoVirtualErro,
                    "Alerta: Link do Balcão Virtual": notificacao.linkBalcaoErro,
                    "Alerta: Telefone do Balcão Virtual": notificacao.telefoneBalcaoErro,
                    "Alerta: Núcleo de Justiça 4.0": notificacao.nucleoErro,
                    "Alerta: Data início do Núcleo": notificacao.dataInicioNucleoErro,
                    "Alerta: Data fim do Núcleo": notificacao.dataFimNucleoErro
                };
            });

            super.downloadFile("alertas_serventias", notificacoesCSV);
            this.dialog.closeAll();
        });
    }

}