import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';

export interface CargoServidor {
  id: string;
  descricao: string;
}

@Component({
  selector: 'app-lista-servidores-cargos',
  templateUrl: './lista-servidores-cargos.component.html',
  styleUrls: ['./lista-servidores-cargos.component.css']
})
export class ListaServidoresCargosComponent extends ListaInformacao implements AfterViewInit{

  cargosServidores: CargoServidor[] = [];
  displayedColumns: string[] = ['id','descricao'];
  dataSource = new MatTableDataSource<CargoServidor>(this.cargosServidores);

  constructor(private http : HttpClient) {
    
    super();
    const url = `${environment.API}/v2/tribunais/servidores/cargos`;
    
    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:CargoServidor) => {
        this.cargosServidores.push(  {
          id: element.id,
          descricao: element.descricao
        } )
      });  

      this.dataSource.data  = this.cargosServidores;          
    });
  }  

  filtrarCargosServidores(event: any): CargoServidor[] {   

    const filterValue = event.target.value.toLowerCase();

    const cargosServidoresFiltrados = this.cargosServidores.filter( cargos => {
      const id = cargos.id.toString();
      const descricao = cargos.descricao.toLowerCase();
      return id.includes(filterValue) || descricao.includes(filterValue);
    });
    
    this.dataSource.data  = filterValue == "" ? this.cargosServidores : cargosServidoresFiltrados;
    return cargosServidoresFiltrados;
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  override downloadFile(){
    super.downloadFile("servidor_tipos", this.cargosServidores);
  } 
}