import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from 'src/app/app.component';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { debounceTime, Subject } from 'rxjs';
import { CarregandoComponent } from '../carregando/carregando.component';
import { MatDialog } from '@angular/material/dialog';

export interface Serventia {
  codigoServentia: string;
  nomeServentia: string;
  codigoNomeMunicipio: string;
  UF: string;
  situacao: string;
}

@Component({
  selector: 'app-lista-serventias-tribunal',
  templateUrl: './lista-serventias-tribunal.component.html',
  styleUrls: ['./lista-serventias-tribunal.component.css', '../../util/css/tabela-pdpj-compacta.css']
})
export class ListaServentiasTribunalComponent extends ListaInformacao implements AfterViewInit {
  
  serventias: Serventia[] = [];  
  displayedColumns: string[] = ['codigoServentia', 'nomeServentia', 'codigoNomeMunicipio', 'UF'];
  dataSource = new MatTableDataSource<Serventia>(this.serventias);
  exibirInativos= false;
  totalItems = 0;
  
  private searchSubject = new Subject<void>();
  private url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/serventias/`;
  
  tiposUnidadeJudiciaria = [
    {id: 1, value: "AADJ"},
    {id: 2, value: "UJ1"},
    {id: 3, value: "UJ2"},
    {id: 4, value: "ND"},
  ];

  constructor(private http : HttpClient, public dialog: MatDialog) {

    super();    

    if(AppComponent.usuario.logado) this.setServentias(); 
    
    // atualiza quando um novo arquivo é enviado
    HomeComponent.emissorAtualizacaoDados.subscribe((tipo)=>{
      if(tipo == "serventia") this.paginator.pageIndex == 0 ? this.setServentias(0) : this.paginator.firstPage();
    });
  }
  
  setServentias(page : number = 0){

    this.serventias = [];
    const pesquisa = document.getElementById("pesq-serventias-tribunal") as HTMLInputElement;

    let urlPaginado = this.url;
    urlPaginado +=`?page=${page}&size=5`;
    if(!this.exibirInativos) urlPaginado += "&ativos=S";    
    if(pesquisa != null && pesquisa.value != "") urlPaginado += "&filtro=" + pesquisa.value;

    this.http.get(urlPaginado).subscribe((resultado: any) => { 

      resultado.result.content.forEach((element:any) => {
        
        this.serventias.push( {
          codigoServentia: element.id,
          nomeServentia: element.nome,
          codigoNomeMunicipio: element.idMunicipioOrigem + " - " + element.nomeMunicipio,
          UF: element.uf,
          situacao: element.situacao
        } );
      });      
      
      this.dataSource.data  = this.serventias;
      this.totalItems = resultado.result.totalElements;       
      HomeComponent.emissorCarregando.emit("serventias-lista");
    });
  }  

  mudancaPagina(event: PageEvent) {
    this.setServentias(event.pageIndex);
  }

  filtrarServentias() {               
    this.searchSubject.next();    
  } 

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  
  ngAfterViewInit() {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe( () => {
      this.paginator.pageIndex == 0 ? this.setServentias(0) : this.paginator.firstPage();
    });
  }

  override downloadFile() {
    
    let url = this.url;
    if (!this.exibirInativos) url += "?ativos=S";
    this.dialog.open(CarregandoComponent, { disableClose: true });
    
    this.http.get(url).subscribe((resultado: any) => { 

      const serventiasCSV: any[] = [];

      resultado.result.forEach((element:any) => {
                
        const ativo = element.situacao == "S" || element.situacao == "A";
       	                
        serventiasCSV.push( {
          "Código Serventia": element.id,
          "Nome da Serventia": element.nome,
          "Endereço": element.endereco,
          "CEP": element.cep,
          "Telefone": element.telefone,
          "Email": element.email,
          "Código da Unidade de Origem (OOOO)": element.codigo_unidade != null ? element.codigo_unidade.padStart(4,"0") : "",
          "Juizo 100% Digital": element.juizo_digital ? "S" : "N",
          "Data Adesão": element.dtAdesaoJuizoDigital != null ? this.formataData(element.dtAdesaoJuizoDigital) : "",
          "Data Término da Adesão": element.dtTerminoJuizoDigital != null ?  this.formataData(element.dtTerminoJuizoDigital) : "",
          "Tipo de Unidade Judiciária": element.idTipoUnidadeJudiciaria != null ? this.tiposUnidadeJudiciaria.filter(tp=> tp.id == element.idTipoUnidadeJudiciaria)[0].value : "" ,
          "Classificação da Unidade Judiciária": element.idClassificacaoUnidadeJudiciaria,
          "Instalação": element.dtInstalacao != null ? this.formataData(element.dtInstalacao) : "",
          "Municípios Abrangidos":  element.idMunicipiosAbrangidos != null ? element.idMunicipiosAbrangidos.join(",") : "",
          "Competências":  element.idCodigosCompetencias != null ? element.idCodigosCompetencias.join(","): "",
          "Latitude": "'"+element.latitude.toString()+"'",
          "Longitude": "'"+element.longitude.toString()+"'",
          "Entrância": element.entrancia != null ? element.entrancia : "",
          "Balcão Virtual": element.balcaoVirtual ? "S" : "N",
          "Link do Balcão Virtual": element.linkBalcao != null ?  element.linkBalcao : "",
          "Telefone do Balcão Virtual": element.telefoneBalcao != null ? element.telefoneBalcao : "" ,
          "Núcleo de Justiça 4.0": element.nucleo != null ? element.nucleo : "" ,
          "Data início do Núcleo": element.dtInicioNucleo != null ? this.formataData(element.dtInicioNucleo) : "",
          "Data fim do Núcleo": element.dtFimNucleo != null ? this.formataData(element.dtFimNucleo) : "",
          "Status": ativo ? "Ativo" : "Inativo"         
        });
        
      });
      
      super.downloadExcel("serventias", serventiasCSV);      
      this.dialog.closeAll();
    });    
  } 

  trocaExibirInativos(){
    this.exibirInativos = !this.exibirInativos;
    this.paginator.pageIndex == 0 ? this.setServentias(0) : this.paginator.firstPage();
  }
  
  classElement(serventia:Serventia):string{
    const situacaoInativa = !(serventia.situacao == "S" || serventia.situacao == "A");
    return situacaoInativa ? "sp-inativo" : ""; 
  }
  
}