import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AlertaComponent } from 'src/app/components/alerta/alerta.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../app.component.css']
})
export class LoginComponent {
    
  constructor(public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private http : HttpClient) {}

  ngAfterViewInit(): void { 
    
    this.route.queryParams.subscribe(param =>{

      const code = param["code"];

      if(code !== undefined){    
             
        const local = encodeURI(window.location.href); 
        
        const url = `${environment.API}/v2/login/token/${code}/?callback=${local}`;
       
        try {

          this.http.get(url).subscribe( {

            next: (resultado:any) => {
                            
              const codigo = resultado.tribunal.id;
              const token = resultado.token.accessToken;

              AppComponent.usuario.codigoOrgao = codigo;
              AppComponent.usuario.home(token);
              this.router.navigate(["/"]);
            }, 
            error: (error) => {              
              this.dialog.open(AlertaComponent, {data: "Não foi possível realizar o login, por favor verique se seu usuário está cadastrado no Corporativo."}); 
            }
                  
          });  
        
        } catch (error) {          
          this.dialog.open(AlertaComponent, {data: "Não foi possível realizar o login, por favor verique se seu usuário está cadastrado no Corporativo."}); 
        }
      }     
    });
  }

  titulo = environment.ambiente;
  toolbar = "toolbar " + environment.toolbarClass;
  
  validCPF(cpf:string) {
    
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999" 
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var i = 1; i <= 10; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
}

  entrar(){    

    const element = document.getElementById("cpf") as HTMLInputElement;  
    const cpf = element.value.replace(/[\s.-]*/igm, '');
    
    if(cpf == ""){
      this.dialog.open(AlertaComponent, {data: "Favor inserir o CPF para continuar"}); 
      return;
    }

    if(cpf.length !== 11){
      this.dialog.open(AlertaComponent, {data: "CPF deve conter 11 números"}); 
      return;
    }

    if(!this.validCPF(cpf)){
      this.dialog.open(AlertaComponent, {data: "Número de CPF inválido"}); 
      return;
    }
    
    AppComponent.usuario.logar(cpf);   
  }
 
}