import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { ArquivoUtil } from 'src/app/util/ArquivoInformacao';
import { Arquivo, ArquivosEnviados } from './arquivo.model';
import { ListaArquivoErros } from './lista-arquivo-erros.component';
import { ListaArquivosEnviados } from './lista-arquivos-enviados.component';
import { environment } from '../../../environments/environment'; 
import { HomeComponent } from 'src/app/pages/home/home.component';

@Component({
  selector: 'app-arquivos-enviados',
  templateUrl: './arquivos-enviados.component.html',
  styleUrls: ['./arquivos-enviados.component.css']
})
export class ArquivosEnviadosComponent extends ArquivoUtil implements OnInit, AfterContentInit{

  @Input() tipo!: string;  
  tooltip !: string;
  quantidade: number = 0;

  constructor(public dialog: MatDialog, private http : HttpClient) { super(); }  

  ngOnInit(): void {
    
    // seta inicialmente os arquivos quando o componente é criado 
    if(AppComponent.usuario.logado && this.tipo == "serventia") this.setArquivosEnviados(this.getTipoURL(this.tipo));
    
    // atualiza quando um novo arquivo é enviado
    HomeComponent.emissorAtualizacaoDados.subscribe((tipo)=>{
      if(tipo == this.tipo) this.setArquivosEnviados(this.getTipoURL(this.tipo));
    });
  }

  ngAfterContentInit(): void {
    this.tooltip = 'Visualizar todos os envios ' + this.getTipoDescricao(this.tipo);
  }

  setArquivosEnviados(tipo:string){

    const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/${tipo}/arquivos/`;       
    
    this.http.get(url+"?page=0&size=1").subscribe((resultado: any) => {      
      HomeComponent.emissorCarregando.emit(tipo+"-enviados");      
      this.quantidade = resultado.result.totalElements;
    });
  }
 
  openDialogArquivosEnviados(pagina: number = 0) {

    const data:ArquivosEnviados = { 
      tipo: this.tipo,  
      pagina: pagina 
    };   

    const dialogRefArquivosEnviados = this.dialog.open(ListaArquivosEnviados, {data: data});

    dialogRefArquivosEnviados.componentInstance.emissorErros.subscribe((arquivoDialogo)=>{
      this.dialog.closeAll();
      const dialogRefArquivoErros = this.dialog.open(ListaArquivoErros, { data: arquivoDialogo.arquivo });
      dialogRefArquivoErros.afterClosed().subscribe( () => {
        this.openDialogArquivosEnviados( arquivoDialogo.pagina );
      });
    }); 
  }
}