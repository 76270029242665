<form [class]="classeForm" >
    
    <input type="file" (change)="fileSelect($event)" id="input-arquivo" [matTooltip]="tooltip" matTooltipPosition="left">
    
    <h2 *ngIf="arquivo == ''" >
        Arraste seu arquivo aqui ou clique nesta área
    </h2>
    
    <h2 *ngIf="arquivo != ''" style="color: #283593"> 
        <mat-icon >article</mat-icon> {{ arquivo | nome_arquivo }}
    </h2>
    
    <div style="padding-top: 12px;margin-bottom:-5px">
        <small style="display: inline-block">
            É recomendável que o arquivo contenha no máximo 1000 registros por envio.
         </small>
    </div>    

    <button mat-stroked-button color="primary" id="upload-not-allowed" *ngIf="arquivo == ''" (click)="alertSelecionarArquivo()" >
        <mat-icon>send</mat-icon> Enviar arquivo para processamento
    </button>

    <button mat-raised-button color="primary" id="upload-allowed" *ngIf="arquivo != ''"  (click)="enviarArquivo()">
        <mat-icon>send</mat-icon> Enviar arquivo para processamento
    </button>
    
    <div *ngIf="show.carregando" >
        <h3>
            Realizando o upload do arquivo, por favor aguarde
        </h3>
        
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    
    <div class="card-msg" style="background-color: #43A047;" *ngIf="show.arquivoEnviadoSucesso">
        <div class="card-icon-box" style="background-color: #2E7D32;">
            <mat-icon fontIcon="check-circle" class="card-icon"></mat-icon>
        </div>
        <div class="card-text">
            O arquivo foi enviado com sucesso
        </div>
        <div class="card-icon-box" >
            <mat-icon fontIcon="close" class="card-icon" (click)="show.arquivoEnviadoSucesso = false"></mat-icon>
        </div>
    </div> 
    
    <div class="card-msg" style="background-color: #EF5350;" *ngIf="show.arquivoEnviadoFalha">
        <div class="card-icon-box" style="background-color: #D32F2F;">
            <mat-icon fontIcon="warning" class="card-icon"></mat-icon>
        </div>
        <div class="card-text">
            Falha ao ler arquivo enviado
        </div>
        <div class="card-icon-box" >
            <mat-icon fontIcon="close" class="card-icon" (click)="show.arquivoEnviadoFalha = false"></mat-icon>
        </div>
    </div>         
    
</form>