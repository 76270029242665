import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertasRegistro, Arquivo, ErrosRegistro } from "./arquivo.model";

interface InformativoRegistro{
    linha: number;
    identificador: string;
    erros : any[] ;
    alertas : any[] ;
}

@Component({
    selector: 'lista-arquivo-erros',
    templateUrl: 'lista-arquivo-erros.component.html',
    styleUrls: ['../../app.component.css']
})
export class ListaArquivoErros{

    arquivo: Arquivo;
    informativos: InformativoRegistro[] = [];
    
    constructor(public dialogRef: MatDialogRef<ListaArquivoErros>, @Inject(MAT_DIALOG_DATA) arquivo: Arquivo) {
        
        this.arquivo = arquivo;

        const arrInfo: InformativoRegistro[] = [];
        
        this.arquivo.erros.forEach((errosRegistro:ErrosRegistro)=>{
            arrInfo.push({
                linha: errosRegistro.linha,
                identificador: errosRegistro.identificador,
                erros: errosRegistro.erros,
                alertas: []
            });
        });

        this.arquivo.alertas.forEach((alertasRegistro:AlertasRegistro)=>{

            const index = arrInfo.findIndex((info)=> info.linha == alertasRegistro.linha );

            if(index > -1){
                arrInfo[index].alertas = alertasRegistro.alertas;
            }else{
                arrInfo.push({
                    linha: alertasRegistro.linha,
                    identificador: alertasRegistro.identificador,
                    erros: [],
                    alertas: alertasRegistro.alertas                    
                });
            }           
        });       
        
        this.informativos = arrInfo.sort( (a, b) => { return a.linha - b.linha } );
    }  

    totalErros(){
       return this.arquivo.erros.reduce((total: number, errosRegistro: ErrosRegistro) => total + errosRegistro.erros.length , 0);
    } 

    totalAlertas(){
        return this.arquivo.alertas.reduce((total: number, alertasRegistro: AlertasRegistro) => total + alertasRegistro.alertas.length , 0);
    } 
}