<div style="padding: 5px 10px 5px 5px ;font-weight: bold;color: #1e599d;display:flex;justify-content: space-around;">
  <span>
    {{ servidoresDistintos }} servidores 
    <span *ngIf="!exibirInativos">ativos</span> distintos
    <!-- <span *ngIf="exibirInativos"> ( ativos e inativos )</span> -->
  </span>

  <span>    
    {{ forcaAuxiliarDistintos == null ? 0 : forcaAuxiliarDistintos }} forças auxiliares
    <span *ngIf="!exibirInativos">ativas</span> distintas
  </span>  
  
  <span *ngIf="exibirInativos"> ( ativos e inativos )</span>
  
</div> 

<div class="mat-elevation-z2" style="margin-top:10px;">
  
    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
        <mat-label>Buscar pelo <i> CPF </i> ou  <i> nome </i> do quadro de pessoal e auxiliar</mat-label>
        <input type="text" matInput id="pesq-servidores-informacoes" (keyup)="filtrarServidores()">     
      </mat-form-field>
    </form>
  
    <table mat-table [dataSource]="dataSource" style="width: 650px;margin-top:-24px;font-size: 5px !important">
  
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef> CPF </th>
        <td mat-cell *matCellDef="let element"> 
          <span [class]="classElement(element)">{{element.cpf}}</span> 
        </td>
      </ng-container>
  
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> 
          <span [class]="classElement(element)">{{element.nome}}</span> 
        </td>
      </ng-container>  

      <ng-container matColumnDef="orgaoJulgador">
        <th mat-header-cell *matHeaderCellDef> Órgão Julgador </th>
        <td mat-cell *matCellDef="let element"> 
          <span [class]="classElement(element)">{{element.orgaoJulgador}}</span> 
        </td>
      </ng-container> 

      <ng-container matColumnDef="cargo">
        <th mat-header-cell *matHeaderCellDef> Cargo </th>
        <td mat-cell *matCellDef="let element"> 
          <span [class]="classElement(element)" style="cursor: help;" [matTooltip]="descricaoCargo(element.cargo)">{{element.cargo}}</span> 
        </td>
      </ng-container> 

      <ng-container matColumnDef="situacaoProfissional">
        <th mat-header-cell *matHeaderCellDef> Situação Profissional</th>
        <td mat-cell *matCellDef="let element" style="cursor: help;" [matTooltip]="descricaoSituaoProfissional(element.situacaoProfissional)" matTooltipPosition="below"> 
          <span [class]="classElement(element)">
            {{ element.situacaoProfissional }} 
          </span> 
        </td>
      </ng-container>
      
      <ng-container matColumnDef="dataInicioAtuacao">
        <th mat-header-cell *matHeaderCellDef> Data início da situação </th>
        <td mat-cell *matCellDef="let element"> 
          <span [class]="classElement(element)">
            {{formataData(element.dataInicioAtuacao)}} 
          </span>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>
  
    <mat-paginator 
        hidePageSize="true" 
        [pageSizeOptions]="[5]"
        [length]="totalItems"
        showFirstLastButtons
        (page)="mudancaPagina($event)"
        aria-label="Selecione a página">
    </mat-paginator>
  
    <div style="padding: 0px 10px 5px 10px; margin-bottom: 12px; display: flex; justify-content: space-between">
      <mat-checkbox (change)="trocaExibirInativos()" color="primary" matTooltip="Exibir quadro de pessoal e auxiliar inativos">
        <span class="sp-check-inativos"> Exibir Inativos </span> 
      </mat-checkbox>
      <button mat-raised-button color="primary" (click)="downloadFile()" matTooltip="Baixar o quadro de pessoal e auxiliar" matTooltipPosition="left">
        <mat-icon>download</mat-icon> Baixar lista quadro pessoal e auxiliar
      </button>  
    </div>
      
  </div>  