import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'info',
    templateUrl: 'info.component.html',
    styleUrls: ['info.component.css','../../app.component.css']    
})
export class InfoComponent {  
    
    private static versaoInformacao: number = 1;
    private checkInfo = false;
    
    constructor( public dialogRef: MatDialogRef<InfoComponent> ) {  
    } 

    static get versaoConfirmadaLeitura(): number {
        return localStorage.getItem('versaoConfirmadaLeitura') != null ? 
               Number(localStorage.getItem('versaoConfirmadaLeitura')) : 0 ;
    }

    static get exibirInfo(): boolean{
        return this.versaoInformacao > this.versaoConfirmadaLeitura;
    }

    trocaExibirMsg(){

        this.checkInfo = !this.checkInfo;
       
        if(this.checkInfo){
            localStorage.setItem('versaoConfirmadaLeitura', InfoComponent.versaoInformacao.toString());
        }else{
            localStorage.removeItem('versaoConfirmadaLeitura');
        }
    }  
}  