import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, Component, Input } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { AlertaComponent } from '../alerta/alerta.component';
import { ArquivoUtil } from 'src/app/util/ArquivoInformacao';
import { environment } from '../../../environments/environment'; 
import { Router } from '@angular/router';
import { HomeComponent } from 'src/app/pages/home/home.component';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.css']
})
export class CustomFileUploadComponent extends ArquivoUtil implements AfterContentInit {
  
  show = {
    carregando : false,
    arquivoEnviadoSucesso: false,
    arquivoEnviadoFalha: false
  }
  
  arquivo: string = '';
  tooltip: string = '';
  file !:File ;
  classeForm = "border-file-gray";  

  @Input() tipo!: string; 
  
  constructor(public dialog: MatDialog,  private http: HttpClient, private router: Router) {
    super();
  }
  
  ngAfterContentInit(): void {
    let str = "";
    switch(this.tipo){
      case "serventia": 
        str = "às ";
        break;
      case "magistrado": 
        str  =  "aos ";
        break;
      case "servidor": 
        str = "ao ";
        break;    
    }
    
    this.tooltip = 'Certifique-se de que seu arquivo corresponde ' + str + this.getTipoDescricao(this.tipo);
    
  }
  
  verificaAutenticacao(){

    if(!AppComponent.usuario.tokenAtivo){

      const dialogAvisoLogin = this.dialog.open(AlertaComponent, { data: "A autenticação expirou, redirecionando para página de autenticação" });
     
      dialogAvisoLogin.afterClosed().subscribe( () => {
        AppComponent.usuario.deslogar();
        this.router.navigate(["/login"]);
      });
      
      return false;
    } 

    return true;
  }
  
  fileSelect(event: any) {     
      
    if(!this.verificaAutenticacao()){
      return;
    }  

    const arr = event.target.value.split(".");
    const extensao = arr[arr.length-1];
    const extensoesPermitidas = ["xlsx"];
    
    if(extensoesPermitidas.indexOf(extensao) > -1){
      this.arquivo += event.target.value;
      this.file = event.target.files[0];
      this.classeForm = "border-file-blue";
    }else{     
      this.dialog.open(AlertaComponent, {data: "Extensão não permitida"}); 
      this.classeForm = "border-file-gray";     
    }          
  }  

  alertSelecionarArquivo(){
    this.dialog.open(AlertaComponent, {data: "Favor selecionar o arquivo"});    
  }
 
  enviarArquivo(){

    if(!this.verificaAutenticacao()){
      return;
    }  

    this.show.arquivoEnviadoSucesso = false;
    this.show.arquivoEnviadoFalha = false;
    this.show.carregando = true;
    
    const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/${this.getTipoURL(this.tipo)}/arquivos/`;
    
    const formData = new FormData();
    formData.append("arquivo", this.file);  

    const fimPost = ()=> {
      HomeComponent.emissorAtualizacaoDados.emit(this.tipo);
      this.show.carregando = false;
      this.arquivo = "";
      const elmentoUpload = document.getElementById("input-arquivo") as HTMLInputElement;
      elmentoUpload.value = "";      
    }
    
    this.http.post(url, formData).subscribe({

      next: (data:any) => {
       
        switch(data.status){
          case "ok":
            switch(data.result.status){
              case "SUCESSO":
                this.show.arquivoEnviadoSucesso = true;
                if(data.result.jsonAlertas !== null && data.result.jsonAlertas.length > 0){
                  this.dialog.open(AlertaComponent, {data: "Arquivo enviado com sucesso. No entanto, contém alertas."}); 
                }
                break;
              case "SUCESSO_PARCIAL":
                this.show.arquivoEnviadoSucesso = true;
                this.dialog.open(AlertaComponent, {data: "Arquivo enviado com sucesso. Entretanto, nem todos os registros foram salvos."}); 
                break;
              default:
                this.show.arquivoEnviadoFalha = true;
                break;    
            }              
            break;
          case "expectation_failed":
            this.show.arquivoEnviadoFalha = true;            
            break;
          case "not_acceptable": 
            this.show.arquivoEnviadoFalha = true;            
            break;
          case "bad_request": 
            
            if(data.mensagem[0] === "Prazo de envio encerrado"){
              this.dialog.open(AlertaComponent, {data: data.mensagem[0]});  
            }else{
              this.show.arquivoEnviadoFalha = true;
            }
                     
            break; 
          default:
            this.show.arquivoEnviadoFalha = true;            
            break;           
        }
                
        fimPost();
      },

      error: () => {       
        this.show.arquivoEnviadoFalha = true;
        fimPost();                
      }   

    } );    
  } 
  
}

@Pipe({name: 'nome_arquivo'})
export class NomeArquivoPipe implements PipeTransform {
  transform(value: string): string {
    const a = value.split("\\");
    return a[a.length-1];
  }
}