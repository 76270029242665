import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment'; 

export interface Competencia {
  id: string;
  descricao: string;
}

@Component({
  selector: 'app-lista-serventias-competencias',
  templateUrl: './lista-serventias-competencias.component.html',
  styleUrls: ['./lista-serventias-competencias.component.css']
})
export class ListaServentiasCompetenciasComponent extends ListaInformacao implements AfterViewInit{
  
  competencias: Competencia[] = [];
  displayedColumns: string[] = ['id', 'descricao'];
  dataSource = new MatTableDataSource<Competencia>(this.competencias);

  constructor(private http : HttpClient) {

    super();
    const url = `${environment.API}/v2/competencias`;
    
    this.http.get(url).subscribe((resultado: any) => { 

      // ordenando
      const ordenado =  resultado.result.sort((a:any, b:any) => a.numeroOrdem > b.numeroOrdem ? 1 : -1);
      
      ordenado.forEach((element:any) => {
        this.competencias.push(  {
          id: element.id,
          descricao: element.descricao
        } )
      });  

      this.dataSource.data  = this.competencias;
          
    });
  } 
  
  filtrarCompetencias(event: any): Competencia[] {   

    const filterValue = event.target.value.toLowerCase();
    
    const competenciasFiltradas = this.competencias.filter(competencia => { 
      const id = competencia.id.toString();
      const descricao = competencia.descricao.toLowerCase();
      return id.includes(filterValue) || descricao.includes(filterValue);
    });
    
    this.dataSource.data  = filterValue == "" ? this.competencias : competenciasFiltradas;
    return competenciasFiltradas;
  } 

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  override downloadFile(){
    super.downloadFile("competencias", this.competencias);
  }
  
}