import * as XLSX from 'xlsx';

export class ListaInformacao {

  convertToCSV(arr:any) {
    
    const array = [Object.keys(arr[0])].concat(arr)
  
    return array.map(obj => {
      const valores = Object.values(obj);
      let linha = "";
      valores.forEach(coluna => linha += coluna + ";");
      return linha;
    }).join('\n')
  }

  convertToCSV2(arr:any) {
    
    const array = [Object.keys(arr[0])].concat(arr)
  
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }

  downloadFile(nome: string, conteudo: any){    
    const csvContent = this.convertToCSV(conteudo);
    const link = window.document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
    link.setAttribute("download", nome + ".csv");
    link.click();      
  }
  
  downloadExcel( nome: string, conteudo: any[],) {

    const worksheet = XLSX.utils.json_to_sheet(conteudo);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, nome);
    const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    
    const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = nome;
    document.body.appendChild(link);
    link.click();   
  }

  downloadExcelAbas (nome: string, abas: any[])  {

    const workbook = XLSX.utils.book_new();

    abas.forEach((aba) => {
        const worksheet = XLSX.utils.json_to_sheet(aba.conteudo);       
        XLSX.utils.book_append_sheet(workbook, worksheet, aba.titulo);
    });  

    const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = nome;
    document.body.appendChild(link);
    link.click();    
  }

  public formataData(str: string): string{
    
    if(str === "" || str === undefined || str === "0" || str == null){
      return "";
    }  

    const arr1 = str.split("T");
    const arr2 = arr1[0].split("-");
    return `${arr2[2]}/${arr2[1]}/${arr2[0]}`;  
  }
}  