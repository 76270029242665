import { HttpClient } from '@angular/common/http';
import { Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from 'src/app/app.component';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';

export interface Municipio {
  UF: string;
  seq_municipio: string;
  nome: string;
  cod_ibge: string; 
}

@Component({
  selector: 'app-lista-serventias-municipios',
  templateUrl: './lista-serventias-municipios.component.html',
  styleUrls: ['./lista-serventias-municipios.component.css']
})
export class ListaServentiasMunicipiosComponent extends ListaInformacao {

  municipios: Municipio[] = [];
  displayedColumns: string[] = ['UF', 'seq_municipio', 'nome', 'cod_ibge'];
  dataSource = new MatTableDataSource<Municipio>(this.municipios);

  constructor(private http : HttpClient) {
    super();

    if(!AppComponent.usuario.logado){
      return;
    }
    
    const url = `${environment.API}/v2/municipios/tribunal/${AppComponent.usuario.orgao}`;
    
    this.http.get(url).subscribe((resultado: any) => { 

      resultado.result.forEach((element:any) => {
        this.municipios.push(  {
          UF: element.uf,
          seq_municipio: element.id,
          nome: element.nome ,
          cod_ibge: element.codIbge
        } )
      });  

      this.dataSource.data  = this.municipios;
          
    });
  }  

  filtrarMunicipios(event: any): Municipio[] {   
    const filterValue = event.target.value.toLowerCase();
    const municipiosFiltradas = this.municipios.filter(municipio => {
      const id = municipio.seq_municipio.toString();
      const nome = municipio.nome.toLowerCase();
      return id.includes(filterValue) || nome.includes(filterValue);
    });
    this.dataSource.data  = filterValue == "" ? this.municipios : municipiosFiltradas;
    return municipiosFiltradas;
  } 

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  override downloadFile(){
    super.downloadFile("municipios", this.municipios);
  } 
  
}