import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AppComponent } from 'src/app/app.component';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { MatDialog } from '@angular/material/dialog';
import { CarregandoComponent } from '../carregando/carregando.component';
import { debounceTime, Subject } from 'rxjs';

export interface Magistrado {
  cpf: string;
  nome: string;
  orgaoJulgador: string;
  cargo: string;
  situacaoProfissional: number;
  dataInicioSituacao: string;
  dataSaidaSituacao: string;
  ativo: boolean;
}

export interface CargoMagistrado {
  id: string;
  descricao: string 
}

export interface SituacaoMagistrado {
  id: string;
  descricao: string 
}

@Component({
  selector: 'app-lista-magistrados-tribunal',
  templateUrl: './lista-magistrados-tribunal.component.html',
  styleUrls: ['./lista-magistrados-tribunal.component.css', '../../util/css/tabela-pdpj-compacta.css']
})
export class ListaMagistradosTribunalComponent extends ListaInformacao implements AfterViewInit{
  
  magistrados: Magistrado[] = [];  
  displayedColumns: string[] = ['cpf', 'nome', 'orgaoJulgador', 'cargo','situacaoProfissional' , 'dataInicioSituacao' ];
  dataSource = new MatTableDataSource<Magistrado>(this.magistrados);
  exibirInativos= false;  
  cargosMagistrados: CargoMagistrado[] = [];
  situacoesMagistrados: SituacaoMagistrado[] = [];
  magistradosDistintos: Number | null =  null;
  totalItems = 0;
 
  private searchSubject = new Subject<void>(); 
  private url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/magistrados/`;
 
  constructor(private http : HttpClient, public dialog: MatDialog) {

    super();
        
    // Setando cargos
    const urlCargos = `${environment.API}/v2/tribunais/magistrados/cargos`;
    
    this.http.get(urlCargos).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.cargosMagistrados.push( {
          id: element.id,
          descricao: element.descricao
        } )
      });
          
    });
    
    // Setando situações
    const urlSituacoes = `${environment.API}/v2/tribunais/magistrados/situacoes`;

    this.http.get(urlSituacoes).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.situacoesMagistrados.push( {
          id: element.id,
          descricao: element.descricao
        } )
      });
          
    });

    // atualiza quando um novo arquivo é enviado
    HomeComponent.emissorAtualizacaoDados.subscribe((tipo)=>{
      if(tipo == "magistrado") this.paginator.pageIndex == 0 ? this.setMagistrados(0) : this.paginator.firstPage();    
    });
  } 

  ngAfterViewInit() {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe( () => {
      this.paginator.pageIndex == 0 ? this.setMagistrados(0) : this.paginator.firstPage();
    });
  }

  setMagistrados(page: number = 0){    
    
    this.magistrados = [];
    const pesquisa = document.getElementById("pesq-magistrados-informacoes") as HTMLInputElement;

    let urlPaginado = this.url;
    urlPaginado +=`?page=${page}&size=5`;
    if(!this.exibirInativos) urlPaginado += "&ativos=S";    
    if(pesquisa != null && pesquisa.value != "") urlPaginado += "&filtro=" + pesquisa.value;

    this.http.get(urlPaginado).subscribe((resultado: any) => {        
            
      resultado.result.content.forEach((element:any) => {        
        
        this.magistrados.push( {          
          cpf: element.cpf,
          nome: element.nome,
          orgaoJulgador: element.orgaoJulgador != null ? element.orgaoJulgador : "",
          cargo: element.cargo != null ? element.cargo : "",
          situacaoProfissional: element.situacaoProfissional,
          dataInicioSituacao: element.dataInicioSituacao, 
          dataSaidaSituacao: element.dataSaidaSituacao,
          ativo: element.ativo
        } );          
      });

      this.dataSource.data  = this.magistrados;
      this.totalItems = resultado.result.totalElements;                
      HomeComponent.emissorCarregando.emit("magistrados-lista");
    });

    this.http.get(this.url+"distintos/").subscribe((resultado: any) => {        
      this.magistradosDistintos = resultado.result[this.exibirInativos ? 1 : 0];
    });
  }

  mudancaPagina(event: PageEvent) {
    this.setMagistrados(event.pageIndex);
  }

  filtrarMagistrados() {               
    this.searchSubject.next();    
  } 

  trocaExibirInativos(){
    this.exibirInativos = !this.exibirInativos;
    this.paginator.pageIndex == 0 ? this.setMagistrados(0) : this.paginator.firstPage();    
  }

  classElement(magistrado:Magistrado):string{    
    return !magistrado.ativo ? "sp-inativo" : ""; 
  }  
  
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  
  override downloadFile(){
    
    let url = this.url;
    if (!this.exibirInativos) url += "?ativos=S";
    this.dialog.open(CarregandoComponent, { disableClose: true });
    
    this.http.get(url).subscribe((resultado: any) => { 
      
        const magistradosCSV: any[] = [];

        resultado.result.forEach((element:any) => {
          magistradosCSV.push({             
            "CPF": element.cpf,
            "Nome":element.nome, 
            "Naturalidade": element.naturalidade != null ? element.naturalidade : "", 
            "Data Nascimento": element.dataNascimento != null ? this.formataData(element.dataNascimento) : "",
            "Email": element.email != null ? element.email : "",
            "Sexo": element.sexo != null ? element.sexo : "",
            "Identidade de gênero": element.identidadeGenero != null ? element.identidadeGenero : "",
            "Raça / Cor":  element.racaCor != null ? element.racaCor : "",
            "Deficiência": element.deficiencia != null ? element.deficiencia.join(",") : "",
            "Foi aprovado(a) em Regime de Cotas": element.aprovadoCota != null ? element.aprovadoCota : "",
            "Cargo": element.cargo != null ? element.cargo : "",
            "Data posse": element.dataPosse != null ? this.formataData(element.dataPosse) : "",
            "Forma de promoção Desembargador(a)/Juiz(a) Titular": element.promocaoMagistrado != null ?  element.promocaoMagistrado : "",
            "Data da promoção": element.dataPromocao != null ? this.formataData(element.dataPromocao) : "",
            "Órgão de lotação do magistrado(a)": element.orgaoLotacao != null ? element.orgaoLotacao : "",
            "Situação profissional atual": element.situacaoProfissional != null ?  element.situacaoProfissional : "",
            "Data de início da situação": element.dataInicioSituacao != null ? this.formataData(element.dataInicioSituacao) : "",
            "Data de saída da situação": element.dataSaidaSituacao != null ? this.formataData(element.dataSaidaSituacao) : "",
            "Status": element.ativo ? "Ativo" : "Inativo",
            "Exclusão de registro por erro": element.exclusaoPorErro != null ? element.exclusaoPorErro : ""
          });
        });

        super.downloadExcel("magistrados", magistradosCSV);
        this.dialog.closeAll();
      });      
  } 

  descricaoCargo(id:string):string{

    if(id === "" || id === undefined || id === "0" || id == null){
      return "";
    }  


    try {
      const cargo = this.cargosMagistrados.filter( cargo => id == cargo.id )[0];
      return cargo.descricao;
    } catch (error) {
      return "";
    } 

    
  }

  descricaoSituaoProfissional(id:string):string{

    if(id === "" || id === undefined || id === "0" || id == null){
      return "";
    }  

    try {
      const situacao = this.situacoesMagistrados.filter( situacao => id == situacao.id )[0];
      return situacao.descricao;
    } catch (error) {
      return "";
    } 
    
  }
}