<div style="padding: 10px 10px 5px 5px ;font-weight: bold;color: #1e599d">
  <span>
    {{ magistradosDistintos }} magistrados(as) 
    <span *ngIf="!exibirInativos">ativos</span> distintos
    <span *ngIf="exibirInativos"> ( ativos e inativos )</span>
  </span>
</div> 

<div class="mat-elevation-z2" style="margin-top:1px">
  
  <form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
      <mat-label>Buscar pelo <i> CPF </i> ou <i> nome </i> do magistrado(a)</mat-label>
      <input type="text" matInput id="pesq-magistrados-informacoes" (keyup)="filtrarMagistrados()">     
    </mat-form-field>
  </form>

  <table mat-table [dataSource]="dataSource" style="width: 700px;margin-top:-24px"> 

    <ng-container matColumnDef="cpf">
      <th mat-header-cell *matHeaderCellDef> CPF </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)">{{element.cpf}}</span>  
      </td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)">{{element.nome}}</span> 
      </td>
    </ng-container>  

    <ng-container matColumnDef="orgaoJulgador">
      <th mat-header-cell *matHeaderCellDef> Órgão Julg. </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)">{{element.orgaoJulgador}}</span> 
      </td>
    </ng-container>  

    <ng-container matColumnDef="cargo">
      <th mat-header-cell *matHeaderCellDef> Cargo </th>
      <td mat-cell *matCellDef="let element"> 
        <span [class]="classElement(element)" style="cursor: help;" [matTooltip]="descricaoCargo(element.cargo)">{{element.cargo}}</span> 
      </td>
    </ng-container> 

    <ng-container matColumnDef="situacaoProfissional">
      <th mat-header-cell *matHeaderCellDef> Situação</th>
      <td mat-cell *matCellDef="let element" style="cursor: help;" [matTooltip]="descricaoSituaoProfissional(element.situacaoProfissional)" matTooltipPosition="below"> {{ element.situacaoProfissional }} </td>
    </ng-container>

    <ng-container matColumnDef="dataInicioSituacao">
      <th mat-header-cell *matHeaderCellDef> Início situação </th>
      <td mat-cell *matCellDef="let element"> {{formataData(element.dataInicioSituacao)}} </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
  </table>

  <mat-paginator 
        hidePageSize="true" 
        [pageSizeOptions]="[5]"
        [length]="totalItems"
        showFirstLastButtons
        (page)="mudancaPagina($event)"
        aria-label="Selecione a página">
  </mat-paginator>

  <div style="padding: 2px 10px 18px 10px;display: flex; justify-content: space-between">
    <mat-checkbox (change)="trocaExibirInativos()" color="primary" matTooltip="Exibir magistrados inativos">
      <span class="sp-check-inativos"> Exibir Inativos </span>       
    </mat-checkbox>
    <button mat-raised-button color="primary" (click)="downloadFile()" matTooltip="Baixar todos as magistrados (as)" matTooltipPosition="left">
      <mat-icon>download</mat-icon> Baixar lista magistrados
    </button>  
  </div>
    
</div>  