import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { CustomFileUploadComponent, NomeArquivoPipe } from './components/custom-file-upload/custom-file-upload.component';
import { ListaServentiasTribunalComponent } from './components/lista-serventias-tribunal/lista-serventias-tribunal.component';
import { ArquivosEnviadosComponent } from './components/arquivos-enviados/arquivos-enviados.component';
import { MatPaginatorIntlCro } from './util/MatPaginatorIntlCro';
import { ArquivoExemploDownloadComponent } from './components/arquivo-exemplo-download/arquivo-exemplo-download.component';
import { ListaServentiasMunicipiosComponent } from './components/lista-serventias-municipios/lista-serventias-municipios.component';
import { ListaServentiasCompetenciasComponent } from './components/lista-serventias-competencias/lista-serventias-competencias.component';
import { ListaTiposClassificacaoUnidadeComponent } from './components/lista-serventias-tipos-classificacao-unidade/lista-tipos-classificacao-unidade.component';
import { ListaMagistradosTribunalComponent } from './components/lista-magistrados-tribunal/lista-magistrados-tribunal.component';
import { ListaMagistradosStatusComponent } from './components/lista-magistrados-status/lista-magistrados-status.component';
import { ListaMagistradosTiposComponent } from './components/lista-magistrados-tipos/lista-magistrados-tipos.component';
import { ListaMagistradosCargosComponent } from './components/lista-magistrados-cargos/lista-magistrados-cargos.component';
import { ListaServidoresTiposComponent } from './components/lista-servidores-tipos/lista-servidores-tipos.component';
import { ListaServidoresAreasComponent } from './components/lista-servidores-areas/lista-servidores-areas.component';
import { ListaServidoresCargosComponent } from './components/lista-servidores-cargos/lista-servidores-cargos.component';
import { AlertaComponent } from './components/alerta/alerta.component';
import { ListaArquivosEnviados, SitucaoArquivoPipe } from './components/arquivos-enviados/lista-arquivos-enviados.component';
import { ListaArquivoErros } from './components/arquivos-enviados/lista-arquivo-erros.component';
import { ArquivosNotificacaoComponent } from './components/arquivos-notificacao/arquivos-notificacao.component';
import { ListaNotificacoesServentias } from './components/arquivos-notificacao/lista-notificacoes-serventias.component';
import { ListaNotificacoesMagistrados } from './components/arquivos-notificacao/lista-notificacoes-magistrados.component';
import { ListaNotificacoesServidores } from './components/arquivos-notificacao/lista-notificacoes-servidores.component';
import { HomeComponent } from './pages/home/home.component';
import { CarregandoComponent } from './components/carregando/carregando.component';
import { InfoComponent } from './pages/home/info.component';
import { LoginComponent } from './pages/login/login.component';
import { ListaServidoresTribunalComponent } from './components/lista-servidores-tribunal/lista-servidores-tribunal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TokenInterceptor } from './app.interceptor.module';

@NgModule({
  declarations: [
    AppComponent,
    CustomFileUploadComponent,
    ListaArquivoErros,    
    NomeArquivoPipe,    
    SitucaoArquivoPipe,
    ListaServentiasTribunalComponent,
    ArquivosEnviadosComponent,
    ListaArquivosEnviados,
    ArquivoExemploDownloadComponent,
    ListaServentiasMunicipiosComponent,
    ListaServentiasCompetenciasComponent,
    ListaTiposClassificacaoUnidadeComponent,
    ListaMagistradosTribunalComponent,
    ListaMagistradosStatusComponent,
    ListaMagistradosTiposComponent,
    ListaMagistradosCargosComponent,
    ListaServidoresTiposComponent,
    ListaServidoresAreasComponent,
    ListaServidoresCargosComponent,
    AlertaComponent,
    ArquivosNotificacaoComponent,
    ListaNotificacoesServentias,
    ListaNotificacoesMagistrados,
    ListaNotificacoesServidores,
    HomeComponent,
    CarregandoComponent,
    InfoComponent,
    LoginComponent,
    ListaServidoresTribunalComponent                       
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule, 
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,  
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule   
  ],
  providers: [
      { 
        provide: MatPaginatorIntl, 
        useClass: MatPaginatorIntlCro
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }