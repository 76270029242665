import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from './pages/login/usuario.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent { 
  
  constructor(private router: Router) {}
  
  public static usuario : Usuario = new Usuario();

  ngAfterViewInit(): void {
  
    if(AppComponent.usuario.logado){
      this.router.navigate(["/"]);
    } 

  } 
  
}