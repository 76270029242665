<div class="mat-elevation-z2">

  <form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
      <mat-label>Buscar pelo <i>id</i> ou <i>descrição</i> dos tipos de magistrado(a)</mat-label>
      <input type="text" matInput id="pesq-magistrados-tipos" (keyup)="filtrarTiposMagistrados($event)">     
    </mat-form-field>
  </form>

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>  

    <ng-container matColumnDef="descricao">
      <th mat-header-cell *matHeaderCellDef> Descrição </th>
      <td mat-cell *matCellDef="let element"> {{element.descricao}} </td>
    </ng-container>
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
  </table>

  <mat-paginator [pageSizeOptions]="[5]"
                  showFirstLastButtons
                  aria-label="Selecione a página">
  </mat-paginator>

  <div style="padding: 10px 10px 20px 10px;display: flex; justify-content: flex-end">
    <button mat-raised-button color="primary" (click)="downloadFile()">
      <mat-icon>download</mat-icon> Baixar lista tipos magistrados
    </button>  
  </div>
    
</div>  