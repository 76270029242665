import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from 'src/app/app.component';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { debounceTime, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CarregandoComponent } from '../carregando/carregando.component';

export interface Servidor { 
  cpf: string;
  nome: string;
  orgaoJulgador: string;
  cargo: number;
  situacaoProfissional: number;
  dataInicioAtuacao: string;
  dataFimAtuacao: string;
  ativo: boolean;
}

export interface CargoServidor {
  id: string;
  descricao: string 
}

export interface SituacaoServidor {
  id: string;
  descricao: string;
}

@Component({
  selector: 'app-lista-servidores-tribunal',
  templateUrl: './lista-servidores-tribunal.component.html',
  styleUrls: ['./lista-servidores-tribunal.component.css', '../../util/css/tabela-pdpj-compacta.css']
})
export class ListaServidoresTribunalComponent extends ListaInformacao implements AfterViewInit{

  servidores: Servidor[] = [];  
  displayedColumns: string[] = ['cpf', 'nome', 'orgaoJulgador', 'cargo' ,'situacaoProfissional' ,'dataInicioAtuacao'];
  dataSource = new MatTableDataSource<Servidor>(this.servidores);
  exibirInativos= false;
  situacoesProfissionaisInativas: number[] = [7,8,9,10,11,12];
  cargosServidores: CargoServidor[] = [];
  situacoesServidores: SituacaoServidor[] = [];
  servidoresDistintos: Number | null = null;
  forcaAuxiliarDistintos: Number | null = null;
  totalItems = 0;

  private searchSubject = new Subject<void>();
  private url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/servidores/`;

  constructor(private http : HttpClient, public dialog: MatDialog) {

    super();

    const urlCargos = `${environment.API}/v2/tribunais/servidores/cargos`;
    
    this.http.get(urlCargos).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.cargosServidores.push( {
          id: element.id,
          descricao: element.descricao
        } )
      });
          
    });


    const url = `${environment.API}/v2/tribunais/servidores/situacoes`;

    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.situacoesServidores.push( {
          id: element.id,
          descricao: element.descricao
        } )
      });
          
    });

    // atualiza quando um novo arquivo é enviado
    HomeComponent.emissorAtualizacaoDados.subscribe((tipo)=>{
      if(tipo == "servidor") this.paginator.pageIndex == 0 ? this.setServidores(0) : this.paginator.firstPage();    
    });
  }  
  
  setServidores(page : number = 0){
    
    this.servidores = [];
    
    const pesquisa = document.getElementById("pesq-servidores-informacoes") as HTMLInputElement;

    let urlPaginado = this.url;
    urlPaginado +=`?page=${page}&size=5`;
    if(!this.exibirInativos) urlPaginado += "&ativos=S";    
    if(pesquisa != null && pesquisa.value != "") urlPaginado += "&filtro=" + pesquisa.value;

    this.http.get(urlPaginado).subscribe((resultado: any) => { 
     
      resultado.result.content.forEach((element:any) => {
        
        this.servidores.push( {          
          cpf: element.cpf,
          nome: element.nome,
          orgaoJulgador: element.orgaoJulgador,
          cargo: element.cargo != null ? element.cargo : "",
          situacaoProfissional: element.situacaoProfissional,
          dataInicioAtuacao: element.dataInicioAtuacao,
          dataFimAtuacao: element.dataFimAtuacao,
          ativo: true 
        } ); 
        
      });  

      this.dataSource.data  = this.servidores;
      this.totalItems = resultado.result.totalElements;
      HomeComponent.emissorCarregando.emit("servidores-lista");
          
    });

    this.http.get(this.url+"distintos/").subscribe((resultado: any) => {        
      this.servidoresDistintos = resultado.result[this.exibirInativos ? 2 : 0];
      this.forcaAuxiliarDistintos = resultado.result[this.exibirInativos ? 3 : 1];
    });
   
  } 
  
  trocaExibirInativos(){
    this.exibirInativos = !this.exibirInativos;
    this.paginator.pageIndex == 0 ? this.setServidores(0) : this.paginator.firstPage();    
  }

  classElement(servidor:Servidor):string{    
    return !servidor.ativo ? "sp-inativo" : ""; 
  }

  mudancaPagina(event: PageEvent) {
    this.setServidores(event.pageIndex);
  }
  
  filtrarServidores() {   
   this.searchSubject.next();
  } 

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe( () => {
      this.paginator.pageIndex == 0 ? this.setServidores(0) : this.paginator.firstPage();
    });
  }
  
  override downloadFile(){

    let url = this.url;
    if (!this.exibirInativos) url += "?ativos=S";
    this.dialog.open(CarregandoComponent, { disableClose: true });
    
    this.http.get(url).subscribe((resultado: any) => { 
      
      const servidoresCSV: any[] = [];

      resultado.result.forEach((element:any) => {
        servidoresCSV.push( {
          "CPF": element.cpf,
          "Nome":element.nome,  
          "Naturalidade": element.naturalidade != null ? element.naturalidade : "",
          "Data Nascimento": element.dataNascimento != null ? this.formataData(element.dataNascimento) : "",
          "Email": element.email != null ? element.email : "",
          "Sexo": element.sexo != null ? element.sexo : "",
          "Identidade de gênero": element.identidadeGenero != null ? element.identidadeGenero : "",
          "Raça / Cor": element.racaCor != null ? element.racaCor : "",
          "Deficiência": element.deficiencia != null ? element.deficiencia.join(",") : "",
          "Foi aprovado(a) em Regime de Cotas": element.cota != null ? element.cota : "",
          "Cargo": element.cargo != null ? element.cargo : "",
          "Área de atuação": element.areaAtuacao != null ? element.areaAtuacao : "",
          "Data posse": element.dataPosse != null ? this.formataData(element.dataPosse) : "",
          "Órgão de lotação do(a) Servidor(a) ou Auxiliar": element.idServentia != null ? element.idServentia : "",
          "Situação profissional atual":  element.situacaoProfissional != null ? element.situacaoProfissional : "",
          "Data de início da situação":  element.dataInicioAtuacao != null ? this.formataData(element.dataInicioAtuacao) : "",
          "Data de saída da situação": element.dataFimAtuacao != null ? this.formataData(element.dataFimAtuacao) : "",
          "Status": element.ativo ? "Ativo" : "Inativo",
          "Exclusão de registro por erro": element.excluido != null ? element.excluido : ""          
        });
      });

      super.downloadExcel("quadro_pessoal_e_auxiliar", servidoresCSV);
      this.dialog.closeAll();
    });
      
  }
  
  descricaoCargo(id:string):string{

    if(id === "" || id === undefined || id === "0" || id == null){
      return "";
    }  


    try {
      const cargo = this.cargosServidores.filter( cargo => id == cargo.id )[0];
      return cargo.descricao;
    } catch (error) {
      return "";
    } 

    
  }

  descricaoSituaoProfissional(id:string):string{

    if(id === "" || id === undefined || id === "0" || id == null){
      return "";
    }
     
    try {
        const situacao = this.situacoesServidores.filter( situacao => id == situacao.id )[0];
        return situacao.descricao;
    } catch (error) {
      return "";
    }    
    
  }
}