<style>

</style>

<div style="display: flex;justify-content: space-between;padding: 40px 40px 30px 20px;min-width: 1000px;">
    <div style="padding-top:-5px">        
        <img src="assets/logo/MPM_Logo_Principal_Horizontal.png" style="width: 180px;height: auto">
        
    </div>
    <h1 mat-dialog-title class="h1-modal-erro" style="margin-top: -10px;margin-left: -45px;">Erros de Envio </h1>
    <div style="padding-top:2px">
        <button mat-raised-button [mat-dialog-close] cdkFocusInitial color="primary">Fechar</button>
    </div>
</div>

<div class="box-info-modal">    
    <div> 
        <div style="font-weight: bold;margin-bottom: 5px">Nome arquivo orginal</div>
        <div>{{ arquivo.nomeArquivoOriginal }} </div> 
    </div>
    <div> 
        <div style="font-weight: bold;margin-bottom: 5px"> 
            {{ arquivo.erros.length > 1 ? "Registros" : "Registro" }} com erro 
        </div>   
        <div> {{ arquivo.erros.length }} </div>
    </div>
    <div> 
        <div style="font-weight: bold;margin-bottom: 5px"> Total de erros </div> 
        <div> {{ totalErros() }}  </div>
    </div>
    <div></div>
    <div> 
        <div style="font-weight: bold;margin-top: 25px"> 
            {{ arquivo.alertas.length > 1 ? "Registros" : "Registro" }} com alertas 
        </div>   
        <div> {{ arquivo.alertas.length }} </div>
    </div>
    <div> 
        <div style="font-weight: bold;margin-top: 25px"> Total de alertas </div> 
        <div> {{ totalAlertas() }}  </div>
    </div>

</div>

<mat-dialog-content class="mat-typography">

    <div class="box-linha" *ngFor="let info of informativos" >

        <div class="box-linha-rotulo">
            {{info.identificador}} <br>
            <small>
                {{ info.erros.length > 1 ? "erros" : "erro" }} :  {{ info.erros.length }} <br>
                {{ info.alertas.length > 1 ? "alertas" : "alerta" }} : {{ info.alertas.length }} 
            </small>            
        </div> 
        
        <div class="box-linha-conteudo">
            <div *ngFor="let erro of info.erros" class="el el-erro">
                {{ erro }}
            </div>
            <div *ngFor="let alerta of info.alertas" class="el el-alerta" >
                {{ alerta }}
            </div>
        </div> 

    </div>
      
</mat-dialog-content>    