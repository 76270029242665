import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';

export interface TipoServidor {
  id: string;
  descricao: string;
}

@Component({
  selector: 'app-lista-servidores-tipos',
  templateUrl: './lista-servidores-tipos.component.html',
  styleUrls: ['./lista-servidores-tipos.component.css']
})
export class ListaServidoresTiposComponent extends ListaInformacao implements AfterViewInit{

  tiposServidores: TipoServidor[] = [];
  displayedColumns: string[] = ['id','descricao'];
  dataSource = new MatTableDataSource<TipoServidor>(this.tiposServidores);

  constructor(private http : HttpClient) {
    
    super();
    const url = `${environment.API}/v2/tribunais/servidores/tipos`;
    
    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:TipoServidor) => {
        this.tiposServidores.push(  {
          id: element.id,
          descricao: element.descricao
        } )
      });  

      this.dataSource.data  = this.tiposServidores;          
    });
  }  

  filtrarTiposServidores(event: any): TipoServidor[] {   
    
    const filterValue = event.target.value.toLowerCase();
    
    const tiposServidoresFiltrados = this.tiposServidores.filter( tipos => { 
      const id = tipos.id.toString();
      const descricao = tipos.descricao.toLowerCase();
      return id.includes(filterValue) || descricao.includes(filterValue);
    });

    this.dataSource.data  = filterValue == "" ? this.tiposServidores : tiposServidoresFiltrados;
    return tiposServidoresFiltrados;
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  override downloadFile(){
    super.downloadFile("servidor_tipos", this.tiposServidores);
  } 
}