
<style>

    :host {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";      
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  
    p {
      margin: 0;
    }
  
    .spacer {
      flex: 1;
    } 
    
   
    @media screen and (max-width: 767px) {
     
    }
  
    @media screen and (max-width: 575px) {
      
    }
  
  </style>
  
  <div [class]="toolbar" role="banner" style="padding: 1px 0px 5px 1px;">  
    
    <img src="assets/logo/MPM_Logo_Principal_Horizontal.png" style="height: 40px;width: auto;margin-left: 24px;margin-top: 5px;" >
    <h2 style="margin-left: 20px;"> {{ titulo }}</h2>
    <div class="spacer"></div>
    <span style="display: inline-block;margin-right: 15px;">{{ tribunal  }}</span> 
    
    <a href="assets/manual.pdf" target="_blank">
    <button mat-stroked-button matTooltip="Visualizar manual" color="primary" style="margin: 0px 10px;" >
      <mat-icon>info</mat-icon> Manual
    </button>
    </a>  
    <a href="assets/FAQ.pdf" target="_blank">
      <button mat-stroked-button matTooltip="Perguntas Frequentes" color="black" style="margin: 0px 5px 0px 0px;" >
        <mat-icon>question_mark</mat-icon> FAQ
      </button>
    </a>  
    <button mat-stroked-button matTooltip="Deslogar no sistema" color="warn" style="margin-right: 15px;" (click)="sair()">
      <mat-icon>logout</mat-icon>Sair
    </button>    
    <img src="assets/banner-datajud.png" id="logo-datajud">  
  </div>
  
  <div id="card-principal" role="main">
  
    <mat-card style="min-height: 98% !important;margin-top:-20px">
  
      <mat-tab-group style="height: 100%;" (selectedTabChange)="loadAba($event)">
  
        <mat-tab style="height: 100%;">
  
          <ng-template mat-tab-label>
            <span class="material-symbols-outlined icon-tab">
              clinical_notes
            </span>
            Serventias
          </ng-template>       
  
          <div style="display: flex; justify-content: space-around; min-height:580px">
                 
            <div style="padding-top: 10px;">   
             <app-lista-serventias-tribunal/>              
            </div> 
  
            <div style="margin: 0px 0px 35px 0px;">            
              <div style="margin: 20px 0px 25px 0px;">
                <div id="box-tabelas">   

                  <button mat-button matTooltip="Tabela de municípios do seu tribunal" class="bt-table margin-table" (click)="selecionarTabelaAuxiliar('serventias-municipios')">
                    <span class="material-symbols-outlined icon-tab box-icon" >
                      table
                    </span>
                    <div class="label-icon"> 
                      Tabela <br> Municípios
                    </div>  
                  </button>

                  <button mat-button matTooltip="Tabela de competências do seu tribunal" class="bt-table margin-table" (click)="selecionarTabelaAuxiliar('serventias-competencias')">
                    <span class="material-symbols-outlined icon-tab box-icon" >
                      table
                    </span>
                    <div class="label-icon"> 
                      Tabela <br> Competências
                    </div>  
                  </button>

                  <button mat-button matTooltip="Tabela de classificação unidade" class="bt-table margin-table" (click)="selecionarTabelaAuxiliar('serventias-tipos-classificacao-unidade')">
                    <span class="material-symbols-outlined icon-tab box-icon" >
                      table
                    </span>
                    <div class="label-icon"> 
                      Tabela <br> Classificação
                    </div>  
                  </button>
                 
                </div>
                <div>
                  <app-arquivo-exemplo-download tipo="serventia" />                  
                  <app-arquivos-notificacao tipo="serventia" />
                  <app-arquivos-enviados tipo="serventia"/>
                </div>
                                                          
              </div>
              <app-custom-file-upload tipo="serventia"/>
            </div>  
  
          </div>
          
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="icon-tab">balance</mat-icon>
            Magistrados(as)
          </ng-template>
  
          <div style="display: flex; justify-content: space-around; min-height:525px;">
            
          <div>
            <app-lista-magistrados-tribunal/>             
          </div>    
          <div  style="margin: 0px 0px 35px 0px;">         
            <div style="margin: 12px 0px 25px 0px;">
              <button mat-button matTooltip="Todas as tabelas de magistrados e quadro pessoal e auxiliar" class="bt-table" (click)="direcionarBaixarTabelas()" style="padding: 24px 15px 37px 15px;width: 100%;">
                <span class="material-symbols-outlined icon-tab box-icon" >
                  table
                </span>
                <div class="label-icon" > 
                  Todas as tabelas auxiliares de <br> serventias, magistrados e quadro pessoal e auxiliar
                </div>  
              </button>
              <app-arquivo-exemplo-download tipo="magistrado"/>               
              <app-arquivos-notificacao tipo="magistrado" />   
              <app-arquivos-enviados tipo="magistrado" />                    
            </div>
            <app-custom-file-upload tipo="magistrado"/>
          </div> 
        </div>     
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
          <mat-icon class="icon-tab">group</mat-icon>
           Quadro de pessoal e auxiliar
          </ng-template>
          <div style="display: flex; justify-content: space-around; min-height:525px;">
            
            <div>
              <app-lista-servidores-tribunal/>              
            </div>
            <div  style="margin: 0px 0px 35px 0px;">         
              <div style="margin: 20px 0px 25px 0px;">
                <button mat-button matTooltip="Todas as tabelas de magistrados e quadro pessoal e auxiliar" class="bt-table" (click)="direcionarBaixarTabelas()" style="padding: 24px 15px 37px 15px;width: 100%;">
                  <span class="material-symbols-outlined icon-tab box-icon" >
                    table
                  </span>
                  <div class="label-icon" > 
                    Todas as tabelas auxiliares de <br> serventias, magistrados e quadro pessoal e auxiliar
                  </div>  
                </button>
                <app-arquivo-exemplo-download tipo="servidor"/>                
                <app-arquivos-notificacao tipo="servidor" /> 
                <app-arquivos-enviados tipo="servidor" />                           
              </div>
              <app-custom-file-upload tipo="servidor"/>
            </div>
          </div>
          
        </mat-tab>

        <mat-tab style="height: 100%;">
  
          <ng-template mat-tab-label>
            <span class="material-symbols-outlined icon-tab" id="abaTabelasAuxiliares">
              table
            </span>
            Tabelas auxiliares
          </ng-template>

          <div style="padding: 0px 0px;margin-bottom:50px;display:flex;justify-content: space-around;">    
            <div>
              <h2 style="margin: 30px 0px 25px 0px; display:inline-block">Tabelas Serventias</h2>  
              
              <h4 style="margin-left: 5px; display:inline-block; color: #333">
                ( Código Órgão Julgador: {{ orgaoOrigem }} )
              </h4> 

              <mat-accordion style="width: 100%;margin-bottom:200px" >            
                <mat-expansion-panel>
                  <mat-expansion-panel-header (click)="abrirListaInformacao('serventias-municipios')">
                    <mat-panel-title>
                      <span id="sp-tabela-serventias-municipios">Tabela de municípios do seu tribunal</span>
                    </mat-panel-title>                  
                  </mat-expansion-panel-header>
                  <app-lista-serventias-municipios/>
                </mat-expansion-panel>
                <mat-expansion-panel >
                  <mat-expansion-panel-header (click)="abrirListaInformacao('serventias-competencias')">
                    <mat-panel-title>
                      <span id="sp-tabela-serventias-competencias">Tabela de competências das serventias</span>
                    </mat-panel-title>                 
                  </mat-expansion-panel-header>
                  <app-lista-serventias-competencias/>
                </mat-expansion-panel>
                <mat-expansion-panel> 
                  <mat-expansion-panel-header (click)="abrirListaInformacao('serventias-tipos-classificacao-unidade')">
                    <mat-panel-title>  
                      <span id="sp-tabela-serventias-tipos-classificacao-unidade">Tabela de tipos de classificação de unidade</span>
                    </mat-panel-title>                  
                  </mat-expansion-panel-header>
                  <app-lista-tipos-classificacao-unidade/>
                </mat-expansion-panel>
              </mat-accordion> 
            </div>

            <div>
              <h2 style="margin: 30px 0px 15px 0px;"> Outras tabelas auxiliares </h2>
              <button mat-button matTooltip="Baixar todas as tabelas de magistrados e quadro pessoal e auxiliar" class="bt-table" (click)="baixarTabelasAuxiliares()" style="padding: 30px 15px 50px 15px;width: 100%;" id="bt-download-auxiliares">
                <span class="material-symbols-outlined icon-tab box-icon" >
                  table
                </span>
                <div class="label-icon" > 
                  Baixar todas as tabelas auxiliares de <br> serventias, magistrados e quadro pessoal e auxiliar
                </div>  
              </button>

              <h2 style="margin: 30px 0px 15px 0px;"> Códigos tribunais </h2>
              <button mat-button matTooltip="Baixar lista dos códigos tribunais" class="bt-table" (click)="baixar('Códigos dos Tribunais.pdf')" style="padding: 30px 15px 50px 15px;width: 100%;" id="bt-download-auxiliares">
                <span class="material-symbols-outlined icon-tab box-icon" >
                  table
                </span>
                <div class="label-icon" > 
                  Baixar lista dos códigos tribunais
                </div>  
              </button>

              <div *ngIf="orgaoOrigem == 1">
                <h2 style="margin: 30px 0px 15px 0px;"> Baixar CSV's alertas gerais </h2>
                <button class="bt-relatorio" (click)="baixarCSV('serventias')">
                  <span class="material-symbols-outlined" >
                    download 
                  </span>
                  <br> Serventias
                                    
                </button>
                <button class="bt-relatorio" (click)="baixarCSV('magistrados')">
                  <span class="material-symbols-outlined" >
                    download 
                  </span>
                  <br> Magistrados
                </button>
                <button class="bt-relatorio" (click)="baixarCSV('servidores')">
                  <span class="material-symbols-outlined" >
                    download 
                  </span>
                  <br>  Quadro de pessoal e auxiliar 
                </button>
              </div>

              <div *ngIf="carregandoRelatorio" >
                <h4>
                    Carregando arquivo, por favor aguarde
                </h4>
                
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>

            </div>  
            
          </div>  
        </mat-tab>
        

      </mat-tab-group>
     
    </mat-card>
  
    <div style="height:60px"></div>
  
    <footer>
      <img src="assets/cnj.jpg" style="width: 105px;margin: 10px 0px 0px 20px">
      <img src="assets/pdjp-marcas-simbolo.svg" style="width: 37px;height: auto;margin: 4px 20px 0px 0px;float:right">
    </footer>
  
  </div>
  