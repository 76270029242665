<div style="display: flex; justify-content: space-between;padding: 10px 30px;margin: 20px 0px 45px 0px;">
    
    <img src="assets/logo/MPM_Logo_Principal_Horizontal.png" style="width: 180px;height: auto">
    
    <div >
        <mat-checkbox color="primary" (change)="trocaExibirMsg()" matTooltip="Não exibir mais esta menssagem neste navegador" style="margin-right: 20px;">
            <span style="margin-left: -10px;">Não exibir mais esta menssagem </span> 
        </mat-checkbox>
        <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary">Fechar</button>
    </div>    
</div>

<div style="padding: 10px 30px 50px 30px;margin-top: -30px;">


    <div class="warning">
        <!-- <mat-icon fontIcon="warning" style="color: #F57F17;display: inline-block;margin: 30px;"></mat-icon> -->
        <div style="width: 100%;">
        
            <span class="material-icons" style="display: inline-block;color: #F57F17; fill: #000; ; vertical-align: bottom;line-height: 0.8 !important;margin-left: 0px;">
                warning
            </span>   

            <div style="display: inline-block;margin-left: 10px;">
                Lembramos aos tribunais que houve modificações no glossário de todos os arquivos modelos, além de mudanças em alguns alertas.
            </div>

            <a href="assets/Modificações MPM.pdf" target="_blank" class="link">
                <div style="display: inline-block;padding-top: -10px;margin-left: 32px;">
                    <span class="material-symbols-outlined" style="vertical-align: bottom;line-height: 0.8 !important;padding-top: 10px;">
                        open_in_new
                    </span>
                    <u>Clique aqui para visualizar o detalhamento das modificações</u>
                </div>            
            </a>
        </div>  

    </div>   
    
    <h4>Serventias</h4> 
    
    <ul>
        <li>3 Novos campos</li>
        <li>Alterações nos campos Competências e Classificação das unidades judiciárias: exclusão e inserção de opções de preenchimento</li>
    </ul>
      
   
    <h4>Magistrados</h4> 
    
    <ul>
        <li>2 Novos campos</li>
        <li>Campo <i>Cargo</i> como chave</li>
        <li>Campos <i>Sexo, Identidade de gênero, Raça/cor, Deficiência, Regime de cotas e Situação profissional atual</i> : Atualização das opções de preenchimento.</li>        
    </ul>

    
    <h4>Quadro de pessoal e auxiliar</h4> 
    
    <ul>
        <li> Campos <i>Sexo, Identidade de gênero, Raça/cor, Deficiência, Regime de cotas</i> : Atualização das opções de preenchimento </li>       
    </ul>

</div>