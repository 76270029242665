import { HttpClient } from '@angular/common/http';
import { Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment'; 

export interface StatusMagistrado {
  seqStatus: string;
  descricao: string;
  descricaoCompleta: string;
}

@Component({
  selector: 'app-lista-magistrados-status',
  templateUrl: './lista-magistrados-status.component.html',
  styleUrls: ['./lista-magistrados-status.component.css']
})
export class ListaMagistradosStatusComponent extends ListaInformacao{
  
  statusMagistrados: StatusMagistrado[] = [];
  displayedColumns: string[] = ['seq_status','descricao', 'descricao_completa'];
  dataSource = new MatTableDataSource<StatusMagistrado>(this.statusMagistrados);

  constructor(private http : HttpClient) {
    
    super();
    const url = `${environment.API}/v2/magistrados/status`;
    
    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.statusMagistrados.push(  {
          seqStatus: element.seq_status,
          descricao: element.descricao,
          descricaoCompleta: element.descricao_completa
        } )
      });  

      this.dataSource.data  = this.statusMagistrados;
          
    });
  }  

  filtrarStatusMagistrados(event: any): StatusMagistrado[] {   
    const filterValue = event.target.value.toLowerCase();
    const statusMagistradosFiltrados = this.statusMagistrados.filter(status => status.descricao.toLowerCase().includes(filterValue));
    this.dataSource.data  = filterValue == "" ? this.statusMagistrados : statusMagistradosFiltrados;
    return statusMagistradosFiltrados;
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  override downloadFile(){
    super.downloadFile("magistrados", this.statusMagistrados);
  } 
}
