import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'carregando',
    templateUrl: 'carregando.component.html',
    styleUrls: ['carregando.component.css']    
})
export class CarregandoComponent {  
        
    constructor( public dialogRef: MatDialogRef<CarregandoComponent> ) {  
    }    
    
}  