<div class="mat-elevation-z2">

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
        <mat-label>Buscar pelo <i>Seq. município</i> ou <i>nome</i> do municipío</mat-label>
        <input type="text" matInput id="pesq-serventias-municipios" (keyup)="filtrarMunicipios($event)">     
      </mat-form-field>
    </form>
    
    <table mat-table [dataSource]="dataSource" style="width: 650px;">
  
      <ng-container matColumnDef="seq_municipio">
        <th mat-header-cell *matHeaderCellDef> Seq. município </th>
        <td mat-cell *matCellDef="let element"> {{element.seq_municipio}} </td>
      </ng-container>
  
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
      </ng-container>      
  
      <ng-container matColumnDef="cod_ibge">
        <th mat-header-cell *matHeaderCellDef> Cód. IBGE </th>
        <td mat-cell *matCellDef="let element"> {{element.cod_ibge}} </td>
      </ng-container>

      <ng-container matColumnDef="UF">
        <th mat-header-cell *matHeaderCellDef> UF </th>
        <td mat-cell *matCellDef="let element"> {{element.UF}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>
  
    <mat-paginator [pageSizeOptions]="[5]"
                   showFirstLastButtons
                   aria-label="Selecione a página">
    </mat-paginator>

    <div style="padding: 10px 10px 20px 10px;display: flex; justify-content: flex-end">
      <button mat-raised-button color="primary" (click)="downloadFile()">
        <mat-icon>download</mat-icon> Baixar lista de municípios tribunal
      </button>  
    </div>
    
</div>
  