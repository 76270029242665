import { Component, EventEmitter, Inject, Output, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Arquivo, ArquivoDialogo, ArquivosEnviados, ErrosRegistro } from "./arquivo.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppComponent } from "src/app/app.component";
import { ArquivoUtil } from "src/app/util/ArquivoInformacao";

@Component({
    selector: 'lista-arquivos-enviados',
    templateUrl: 'lista-arquivos-enviados.component.html',
    styleUrls: ['lista-arquivos-enviados.component.css','../../app.component.css']
})
export class ListaArquivosEnviados extends ArquivoUtil{  
    
    tituloIcone: string = "";
    titulo: string = "";
    pagina: number = 0;
    displayedColumns: string[] = ['dataImportacao', 'nomeArquivoOriginal', 'situacao', 'quantidadeRecebidos', 'quantidadeSucesso', 'quantidadeRegistrosErro', 'quantidadeRegistrosAlerta', 'erros'];
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @Output() emissorErros = new EventEmitter<ArquivoDialogo>();
    tribunal="";
    totalItems=0;
    arquivos : Arquivo[] = [];
    tipo = "";

    constructor( 
      public dialogRef: MatDialogRef<ListaArquivosEnviados>, 
      private http : HttpClient,
      @Inject(MAT_DIALOG_DATA) arquivosEnviados: ArquivosEnviados       
    ) {
      
      super();      
      this.tipo = arquivosEnviados.tipo;

      switch(arquivosEnviados.tipo){
        case "serventia": 
          this.titulo = "serventias";
          this.tituloIcone = "clinical_notes";
          break;
        case "magistrado": 
          this.titulo = "magistrados(as)";
          this.tituloIcone = "balance";
          break;
        case "servidor": 
          this.titulo = "quadro de pessoal e auxiliar";
          this.tituloIcone = "group";
          break;     
      }

      this.setArquivos(arquivosEnviados.pagina);
    } 
  
    setArquivos(pagina: number = 0 ){

      const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/${this.getTipoURL(this.tipo)}/arquivos/`;
      this.arquivos = [];

      this.http.get(url+`?page=${pagina}&size=5`).subscribe((resultado: any) => {
        resultado.result.content.forEach((element:any) => {
          this.arquivos.push( {
            dataImportacao: this.formataDataHora(element.dataArquivo),
            nomeArquivoOriginal: element.nomeArquivoOriginal, 
            urlArquivoOriginal: element.urlArquivoOriginal,          
            situacao: element.status,
            quantidadeRecebidos: element.qtdRegistrosRecebidos,
            quantidadeSucesso: element.qtdRegistrosSucesso,
            quantidadeRegistrosErro: element.qtdRegistrosFalha,
            quantidadeRegistrosAlerta: element.qtdRegistrosAlerta,
            erros: element.jsonErros !== null ? element.jsonErros : [],
            alertas: element.jsonAlertas !== null ? element.jsonAlertas : []
          } );
        });
        
        this.dataSource.data = this.arquivos;
        this.totalItems = resultado.result.totalElements;
        this.paginator.pageIndex = pagina;
      });        
      
    }

    mudancaPagina(event: PageEvent) {
      this.setArquivos(event.pageIndex);
    }

    getSitucaoIcone(situacao:string){
      switch(situacao){
        case "SUCESSO": return "check"; 
        case "SUCESSO_PARCIAL": return "warning"; 
        case "REMESSA": return "block"; 
        case "RECEBIMENTO": return "description"; 
        case "ERRO": return "priority_high";  
        default: return situacao;
      }  
    }
  
    getSitucaoClass(situacao:string){
      switch(situacao){
        case "SUCESSO": return "green";
        case "SUCESSO_PARCIAL": return "orange"; 
        case "REMESSA": return "blue"; 
        case "RECEBIMENTO": return "purple"; 
        case "ERRO": return "red";  
        default: return situacao;
      }  
    }

    getSitucaoMsg(situacao:string){
      switch(situacao){
        case "SUCESSO": return "Todos os registros foram salvos";
        case "SUCESSO_PARCIAL": return "Apenas alguns registros foram salvos"; 
        case "REMESSA": return "Não foi possível salvar o arquivo"; 
        case "RECEBIMENTO": return "Arquivo em formato incompatível"; 
        case "ERRO": return "Nenhum dos registros foi salvo";  
        default: return situacao;
      }  
    }

    showErrosArquivos(arquivo: Arquivo){        
      this.emissorErros.emit({arquivo: arquivo, pagina: this.paginator.pageIndex} as ArquivoDialogo);
    }    
}
  
@Pipe({name: 'situacao_arquivo'})
export class SitucaoArquivoPipe implements PipeTransform {
    transform(value: string) : string {   
      switch(value){
        case "SUCESSO": return "Sucesso";
        case "SUCESSO_PARCIAL": return "Sucesso parcial";
        case "REMESSA": return "Arquivo não salvo";
        case "RECEBIMENTO": return "Erro de formato";
        case "ERRO": return "Sem sucesso"; 
        default: return value;
      } 
    }
}