import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListaInformacao } from 'src/app/util/ListaInformacao';
import { environment } from '../../../environments/environment';

export interface TiposClassificacaoUnidade {
  tipo_unidade: string;
  id: string;
  descricao: string;
}

@Component({
  selector: 'app-lista-tipos-classificacao-unidade',
  templateUrl: './lista-tipos-classificacao-unidade.component.html',
  styleUrls: ['./lista-tipos-classificacao-unidade.component.css']
})
export class ListaTiposClassificacaoUnidadeComponent extends ListaInformacao{

  tiposClassificacaoUnidade: TiposClassificacaoUnidade[] = [];
  displayedColumns: string[] = ['tipo_unidade','id', 'descricao'];
  dataSource = new MatTableDataSource<TiposClassificacaoUnidade>(this.tiposClassificacaoUnidade);

  constructor(private http : HttpClient) {
    super();
    const url = `${environment.API}/v2/classificacoes`;
    
    this.http.get(url).subscribe((resultado: any) => { 
           
      resultado.result.forEach((element:any) => {
        this.tiposClassificacaoUnidade.push(  {
          tipo_unidade: element.tipo.sigla,
          id: element.id,
          descricao: element.descricao
        } )
      });  

      this.dataSource.data  = this.tiposClassificacaoUnidade;
          
    });
  }  

  filtrarTipos(event: any): TiposClassificacaoUnidade[] {   
    
    const filterValue = event.target.value.toLowerCase();
    
    const tiposClassificacaoUnidadeFiltradas = this.tiposClassificacaoUnidade.filter(tipo => {

      const id = tipo.id.toString();
      const descricao = tipo.descricao.toLowerCase();
      
      return id.includes(filterValue) || descricao.includes(filterValue);
    });
    
    this.dataSource.data  = filterValue == "" ? this.tiposClassificacaoUnidade : tiposClassificacaoUnidadeFiltradas;
    return tiposClassificacaoUnidadeFiltradas;
  } 

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  override downloadFile(){
    super.downloadFile("tipos_classificacao_unidade", this.tiposClassificacaoUnidade);
  } 

}