import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { ArquivoUtil } from 'src/app/util/ArquivoInformacao';
import { CustomFileUploadComponent } from '../custom-file-upload/custom-file-upload.component';
import { ListaNotificacoesMagistrados } from './lista-notificacoes-magistrados.component';
import { ListaNotificacoesServentias } from './lista-notificacoes-serventias.component';
import { ListaNotificacoesServidores } from './lista-notificacoes-servidores.component';
import { Notificacao, NotificacaoMagistrado, NotificacaoServentia, NotificacaoServidor } from './notificacao.model';
import { environment } from '../../../environments/environment'; 
import { HomeComponent } from 'src/app/pages/home/home.component';

export interface NotificacaoExibirExcluidos{
  tipo: string;
  exibirExcluidos: boolean;
}

@Component({
  selector: 'app-arquivos-notificacao',
  templateUrl: './arquivos-notificacao.component.html',
  styleUrls: ['./arquivos-notificacao.component.css', '../../app.component.css']
})
export class ArquivosNotificacaoComponent extends ArquivoUtil implements AfterViewInit, AfterContentInit{

  @Input()
  tipo : string = "" ;
  tooltip: string = '';
  notificacoes : Notificacao[] = [];
  qtdNotificacoes: Number = 0;
  arquivo!: string;
  exibirExcluidos: boolean = false;
  static emissorExibirExcluidos = new EventEmitter<NotificacaoExibirExcluidos>();
  
  constructor(public dialog: MatDialog, private http : HttpClient) { super(); }  

  ngOnInit(): void {     

    // seta inicialmente as notificações quando o componente é criado 
    if(AppComponent.usuario.logado && this.tipo == "serventia") this.setNotificacoes(this.getTipoURL(this.tipo));
    
     // atualiza quando um novo arquivo é enviado
     HomeComponent.emissorAtualizacaoDados.subscribe((tipo)=>{
       if(tipo == this.tipo) this.setNotificacoes(this.getTipoURL(this.tipo));
     });

     ArquivosNotificacaoComponent.emissorExibirExcluidos.subscribe((notificacaoExibirExcluidos :NotificacaoExibirExcluidos)=>{
      if(notificacaoExibirExcluidos.tipo == this.tipo) {
        this.exibirExcluidos = notificacaoExibirExcluidos.exibirExcluidos;
        this.setQuantidadeNotificacoes();
      }
     });
        
  } 

  ngAfterViewInit(): void {
    switch(this.tipo){
      case "serventia":
        this.arquivo = "serventias";        
        break;
      case "magistrado":
        this.arquivo = "magistrados(as)";        
        break; 
      case "servidor":
        this.arquivo = "quadro pessoal e auxiliar";        
        break;    
    }
  }

  ngAfterContentInit(): void {
    this.tooltip = 'Visualizar alertas ' + this.getTipoDescricao(this.tipo);
  }

  setNotificacoes(tipo:string){

    const url = `${environment.API}/v2/tribunais/${AppComponent.usuario.orgao}/${tipo}/alertas/?page=0&size=1`;
    
    this.http.get(url).subscribe((resultado: any) => {            
      HomeComponent.emissorCarregando.emit(tipo+"-alertas");
      this.qtdNotificacoes =  resultado.result.totalElements;            
    });
  }

  setQuantidadeNotificacoes(){
    if(this.tipo == "serventia" || this.exibirExcluidos){
      this.qtdNotificacoes = this.notificacoes.length;
    }else{
      const notificacoes = this.notificacoes.filter( (n) => !n.excluido );
      this.qtdNotificacoes = notificacoes.length;
    }    
  }

  openDialogNotificacoes(){
    switch(this.tipo){
      case "serventia":
        this.dialog.open(ListaNotificacoesServentias);
        break;
      case "magistrado":
        this.dialog.open(ListaNotificacoesMagistrados);
        break; 
      case "servidor":
        this.dialog.open(ListaNotificacoesServidores);
        break;     
    }
   
  }  
  
}
