<mat-card style="display: block;margin-top: 10px">
    <button mat-button color="primary" [matTooltip]="tooltip" matTooltipPosition="left" id="bt" (click)="openDialogArquivosEnviados()">
        <div id="box-icon">         
            <mat-icon>send</mat-icon>
        </div> 
        <div style="margin-top: -5px;"> 
            <span id="sp-qt">{{ quantidade }}</span>            
            {{ quantidade > 1 ? 'Arquivos enviados' : 'Arquivo enviado' }} 
        </div>  
    </button>
</mat-card>