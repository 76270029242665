<style>
  td > span{
    width: 100%;text-align: center
  }
</style>

<div style="display: flex; justify-content: space-between;">
  
  <div>
    
    <div style="display: inline-block;padding-top:-50px;margin:33px 20px 0px 20px;color: #B71C1C">
      <i class="material-icons" style="display: inline-block; vertical-align: centered;">
        notifications
      </i>              
      <div style="display: inline-block;margin-left: 5px;padding-bottom:10px;font-size:24px;vertical-align: top;">
        {{ tribunal }} - serventias
      </div>
    </div>    
  </div>
  <a href="assets/Campos com Alertas-Serventias.pdf" target="_blank">
    <button mat-stroked-button matTooltip="Clique para visualizar os campos com alertas de serventias" color="black" style="margin: 30px 25px;" >
      <mat-icon>info</mat-icon> Campos com alertas 
    </button>
  </a>  
</div>

    
<mat-dialog-content class="mat-typography">

  <div class="mat-elevation-z1">
  
    <table mat-table [dataSource]="dataSource" style="width: 70vw;">
      
      <ng-container matColumnDef="idOrigem">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.idOrigem}} </td>
      </ng-container>

      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
      </ng-container>    

      <ng-container matColumnDef="ativo">
        <th mat-header-cell *matHeaderCellDef> Ativo </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.ativo">Sim</span> 
          <span *ngIf="!element.ativo">Não</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalErros">
        <th mat-header-cell *matHeaderCellDef> Total de alertas </th>
        <td mat-cell *matCellDef="let element"> {{element.totalErros}} </td>
      </ng-container>      

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>
  
    <mat-paginator 
      hidePageSize="true"
      [pageSizeOptions]="[5]"
      [length]="totalItems"
      showFirstLastButtons
      (page)="mudancaPagina($event)"
      aria-label="Selecione a página">
    </mat-paginator>

</div>
   
</mat-dialog-content>

<mat-dialog-actions > 
  
  <div style="display: flex;justify-content: space-between;width: 99%; padding: 0px 10px 10px  10px;">

    <button mat-raised-button color="primary" matTooltip="Baixar todos alertas das serventias" (click)="downloadFile()">
      <mat-icon>download</mat-icon> Baixar alertas das serventias
    </button>  
    
    <button mat-raised-button [mat-dialog-close]="true" color="primary">Fechar</button>
    
  </div>

</mat-dialog-actions>